export const LIFEMENU={
    TOUR : '국내관광',
    PERFORMANCE : "영화공연",
    FOOD :"식품정보",
    CONVENIENCE : "생활편의",
    MEDICAL : "의료정보",
    WEATHER : "날씨환경",
    BOARD :"홍여사게시판",
}

export const TOURISTMENU ={
    TOURREGION : '전국관광지',
    TOURFESTIVAL : '문화축제',
    TOURCOUNTRY : '문화유적',
    TOURPICTURE: '관광지사진',
}
export const PERFORMANCEMENU ={
    PERFORMANCEEVENT : '공연행사',
    PERFORMANCECINEMA : '공공시설',

}

export const FAMILYMENU ={
    FAMILYABANDON : '유기동물 정보',
    FAMILYLOST : '분실물 정보',
}
export const CONVENIENCEMENU ={
    CONVENIENCECAMPING : '전국캠핑',
}
export const BOARDMENU ={
    BOARD : '게시판',
}

export const MEDICALMENU ={
    MEDICALMEDICINE : '의약품개요',
    MEDICALHOSPITAL : '의료기관정보',
    FOODINFOMATION: '건강식품',

}

