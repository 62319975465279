import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { DataContext } from "../../context/Data";
import { UserContext } from "../../context/User";
import { ReadCommunitySummary } from "../../service/CommunityService";
import { ReadRoom } from "../../service/RoomService";
import { ReadWork } from "../../service/WorkService";
import { sleep, useSleep } from "../../utility/common";
import { imageDB } from "../../utility/imageData";

import { ReadCampingRegion, ReadHospitalRegion, ReadHospitalRegion1, ReadPerformanceCinema, ReadPerformanceEvent, ReadTourCountry, ReadTourFestival, ReadTourPicture, ReadTourRegion } from "../../service/LifeService";
import { INCLUDEDISTANCE } from "../../utility/screen";
import "./PCmain.css";
import LottieAnimation from "../../common/LottieAnimation";
import { LoadingMainAnimationStyle } from "../../screen/css/common";
import { Row } from "../../common/Row";
import { readuserbyphone, Update_usertoken } from "../../service/UserService";
import localforage from 'localforage';


const Container = styled.div`
  height: 100vh;
  display : flex;
  margin-top: 15px;
  align-items:center;
  width :100%;
  flex-direction:column;




`
const style = {
  display: "flex"
};

const ButtonLayer = styled.div`

  display : flex;
  flexDirection: row;
  margin-top:20px;

`
const SkipButton = styled.div`
  z-index: 10;
  width: 270px;

  height: 50px;
  background: #131313;

  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  border-radius: 20px;

`
const LoginButton = styled.div`
  z-index: 10;
  width: 270px;

  height: 50px;
  background: #ff7e19;

  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  border-radius: 20px;

`


/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;

const PCGatecontainer =({containerStyle}) =>  {

/** PC 웹 초기 구동시에 데이타를 로딩 하는 component
 * ① 상점정보를 불러온다
 * ② 상점정보를 불러오는 동안 로딩화면을 보여준다
 */

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [loading, setLoading] = useState(true);

  const [isVideoReady, setIsVideoReady] = useState(false);

  const handleCanPlay = () => {
    setIsVideoReady(true);
    console.log('비디오가 재생 준비되었습니다.');
  };

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);


  useEffect(()=>{

  },[refresh])

  /**

   */
  useEffect(()=>{
  }, [])

  const _handleNext =() =>{
    navigate("/PCPhone");
  }

  const _handleTest = async() =>{
    const PHONE = "01062149756";
    const userdata = await readuserbyphone({PHONE});

    // console.log("TCL: _handleCheck -> userdata", userdata)

    user.phone = PHONE;
    dispatch(user);

    const DEVICEID = userdata.USERINFO.deviceid;
    const TOKEN = user.token;
    const LATITUDE = user.latitude;
    const LONGITUDE = user.longitude;

    if(TOKEN != ''){
      const usertoken = await Update_usertoken({DEVICEID, TOKEN });
    }

    // new Promise(resolve => setTimeout(resolve, 1000));

    await sleep(1000);
    user.users_id = userdata.USERS_ID;
    user.deviceid = DEVICEID;
    user.nickname = userdata.USERINFO.nickname;
    user.userimg = userdata.USERINFO.userimg
    user.latitude = userdata.USERINFO.latitude;
    user.longitude = userdata.USERINFO.longitude;
    user.address_name = userdata.USERINFO.address_name;


    dispatch(user);


    localforage.setItem('userconfig', user)
    .then(function(value) {
      navigate("/PCMain");
    })
    .catch(function(err) {
      console.error('데이터 저장 실패:', err);
    });

  }
 
  return (

    <Container style={containerStyle}>

        <div class="intro-content">
        <h1 class="intro-text">구해줘 홍여사에 오신것을 환영합니다</h1>
        </div>

        <video class="background-video"  autoPlay muted controls loop
           onCanPlay={handleCanPlay} 
        >
        <source src={imageDB.introduce1}  type="video/mp4" />
        </video>
  
        <ButtonLayer>
        <SkipButton onClick={_handleTest}>체험해 보기</SkipButton>
        <LoginButton onClick={_handleNext} style={{marginLeft:10}}>구해줘 홍여사 들어가기 </LoginButton>
        </ButtonLayer>

    </Container>
  );

}

export default PCGatecontainer;

