import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../../context/User";
import moment from "moment";
import { imageDB } from "../../../utility/imageData";

import { AroundRow, BetweenRow, FlexstartRow, Row } from "../../../common/Row";
import { Column, FlexstartColumn } from "../../../common/Column";
import { PCCOMMNUNITYMENU, RULLET_TYPE } from "../../../utility/screen";

import { DataContext } from "../../../context/Data";
import { sleep, useSleep } from "../../../utility/common";
import { getDateEx, getNewDate } from "../../../utility/date";
import { readuser, Readuserbyusersid, Update_rulletbyusersid } from "../../../service/UserService";
import LottieAnimation from "../../../common/LottieAnimation";
import { LoadingCommunityStyle } from "../../../screen/css/common";


const Container = styled.div`
 

`
const style = {
  display: "flex"
};

const EventProcessTag = styled.div`
  background: #000;
  width: 100px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  color: #fff;
  height: 40px;
  align-items: center;

`

const EventTitle = styled.div`
  font-size: 20px;
  line-height: 60px;

  border-bottom: 1px solid #ddd;
  width : 85%;
  font-weight:500;

`


const Rulletbg = styled.div`
  text-align: center;

  padding-top: 150px;
  width: 90%;
  margin : 20px auto;



`
const ResultLayer = styled.div`

  font-size: 20px;
  color: #3c4cb2;
  font-weight: 700;
  line-height: 30px;
  z-index: 10;
  position: relative;
  top: 200px;

`
const Comment = styled.div`
  font-size: 14px;
  display: flex;
  padding-left: 20px;
  color: #444;
  height: 30px;
  flex-direction: row;
  justify-content: center;
`


const MobileRulletEvent =({containerStyle}) =>  {


  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [state, setState] = useState(-1);
  const [checkstatus, setCheckstatus] = useState(true);
  const [process, setProcess] = useState(false);
  const [futuredate, setFuturedate] = useState('');
  const [checknum, setChecknum] = useState(-1);

  const [currentloading, setCurrentloading] = useState(true);

  var rolLength = 6; // 해당 룰렛 콘텐츠 갯수
  var setNum; // 랜덤숫자 담을 변수
  var hiddenInput = document.createElement("input");
  hiddenInput.className = "hidden-input";


  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    setCheckstatus(checkstatus);
    setState(state);
    setProcess(process);
    setChecknum(checknum);
    setFuturedate(futuredate);
    setCurrentloading(currentloading);
  },[refresh])



  /**
   * 초기 페이지 진입시에는 context에 있는 정보를 가져온다
  
   */
  useEffect(()=>{
    async function FetchData(){
      const today = moment(); // 현재 날짜
      const futureDate = today.clone().add(1, 'days'); // 1일 후 날짜
      setFuturedate(getNewDate(futureDate));


      const USERS_ID = user.users_id;

      const useritem = await Readuserbyusersid({USERS_ID});
      console.log("TCL: FetchData -> useritem", useritem,getDateEx(today));
      
      if(useritem.RULLET == getDateEx(today)){
        setCheckstatus(false);
        
      }
      setCurrentloading(false);
      setRefresh((refresh) => refresh +1);

    } 
  
    FetchData();
  }, [])


  const rRandom = () => {
    var min = Math.ceil(0);
    var max = Math.floor(rolLength - 1);
    return Math.floor(Math.random() * (max - min)) + min;
  };

  const rRotate = () => {
    var panel = document.querySelector(".rouletter-wacu");
    var btn = document.querySelector(".rouletter-btn");
    var deg = [];
    // 룰렛 각도 설정(rolLength = 6)
    for (var i = 1, len = rolLength; i <= len; i++) {
      deg.push((360 / len) * i);

    }

    
    console.log("TCL: rRotate -> deg", deg); 
    // 랜덤 생성된 숫자를 히든 인풋에 넣기
    var num = 0;
    document.body.append(hiddenInput);
    // setNum = hiddenInput.value = rRandom();


    let min = 0;
    let max = 10;
    let randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
    console.log("TCL: rRotate -> randomInt", randomInt)

    

    if(randomInt < 2){
      setNum = RULLET_TYPE.POINT_1000;
    }else if(randomInt >=2 && randomInt <5){
      setNum = RULLET_TYPE.ZERO_ONE;
    }else if(randomInt >=5 && randomInt <6){
      setNum = RULLET_TYPE.POINT_1000;
    }else if(randomInt >=6 && randomInt <7){
      setNum = RULLET_TYPE.POINT_5000;
    }else if(randomInt >=7 && randomInt <=10){
      setNum = RULLET_TYPE.ZERO_ONE;
    }


    setChecknum(setNum);

    setRefresh((refresh) => refresh +1);
    
    // 애니설정
    var ani = setInterval(() => {
      num++;
      panel.style.transform = "rotate(" + 360 * num + "deg)";
    //  btn.disabled = true; //button,input
    //  btn.style.pointerEvents = "none"; //a 태그
      
      // 총 50에 다달했을때, 즉 마지막 바퀴를 돌고나서
      if (num === 50) {
        clearInterval(ani);

        panel.style.transform = `rotate(${deg[setNum]}deg)`;
      }
    }, 50);
  };
  
  // 정해진 alert띄우기, custom modal등
  // 3 이면 꽝
  // 1 이면 꽝 
  // 2 이면 5000 포인트
  // 4 이면 스타벅스
  // 5 이면 신세계
  // 6 이면 꽝
  const RLayerPopup = async(num) => {
  
    console.log("TCL: RLayerPopup -> num", num)
    
    switch (num) {
      case 1:
        setState(1);
        break;
      case 3:
        setState(2);
        break;
      case 5:
        setState(3);
        break;
      default:
        setState(4);
    }

    setCheckstatus(false);

    setRefresh((refresh) => refresh +1);

    var btn = document.querySelector(".rouletter-btn");
    //btn.disabled = false; //button,input
    const supdate = await useSleep(500);
    // window.scrollTo(0, document.body.scrollHeight);

  };
  
  // reset
  const rReset = (ele) => {
    setTimeout(() => {


      hiddenInput.remove();
    }, 5500);
  };

  const _handlestart = async() =>{

    if(process == true){
      return;
    }

    const USERS_ID = user.users_id;
    const today = moment(); // 현재 날짜
    const RULLETDATE = getDateEx(today);  

    Update_rulletbyusersid({RULLETDATE, USERS_ID});


    setProcess(true);
    setRefresh((refresh) => refresh +1);
  
    rRotate();

    const update = await sleep(5000);

    RLayerPopup(setNum);




  }

  



  return (
    <Container style={containerStyle}>
      {currentloading == true ? (<LottieAnimation containerStyle={LoadingCommunityStyle} animationData={imageDB.loadinglarge}
      width={"100px"} height={'100px'}/>):(     <Column margin={'0px auto;'} width={'100%'} style={{background:"#fff"}} >
      <EventTitle>
        <div style={{marginTop:30}}>
          <EventProcessTag>진행중</EventProcessTag>
        </div>
        <BetweenRow>
          <EventTitle style={{textAlign: "left"}}>{'매일매일 룰렛'}</EventTitle>
          <EventTitle style={{textAlign: "right"}}>{'매일 한번'}</EventTitle>
        </BetweenRow>



      </EventTitle>


      <Rulletbg>
          <div class="rouletter">
            <div class="rouletter-mobilebg">
              <div class="rouletter-wacu"></div>
          </div>
          <div class="rouletter-mobilearrow"><img src = {imageDB.rulletpin} style={{width:40}}/></div>
          {
            checkstatus == true ? (<div class="rouletter-mobilebtn" onClick={_handlestart}><img src = {imageDB.rulletstart} style={{width:100}}/></div>) 
            :(<div class="rouletter-mobilebtn" ><img src = {imageDB.rulletstop} style={{width:100}}/></div>)
          }
          
        </div>
        {
          checkstatus == false && <ResultLayer>
            {
              checknum == RULLET_TYPE.ZERO_ONE && <>
                <div>아쉽네여 다음엔 좋은 결과가 있을거에여</div>
                <Comment>룰렛은 하루에 한번 진행 할수 있습니다.</Comment>
                <Comment>다음 룰렛은  {futuredate} 다시 진행 할수 있습니다.</Comment>
                </>
            }

            {
              (checknum == RULLET_TYPE.POINT_1000 ||
              checknum == RULLET_TYPE.POINT_5000 )
              && <>
              <div>축하 합니다. 경품에 당첨 되었습니다</div>
              <Comment>당첨 내용은 내 포인트 내역에서 확인 할수 있습니다</Comment>
              <Comment>룰렛은 하루에 한번 진행 할수 있습니다.</Comment>
              <Comment>다음 룰렛은  {futuredate} 다시 진행 할수 있습니다.</Comment>
            </>

            }

            {
                checknum == -1 && <>
                  <Comment>룰렛은 하루에 한번 진행 할수 있습니다.</Comment>
                  <Comment>다음 룰렛은  {futuredate} 다시 진행 할수 있습니다.</Comment>
                  </>
              }
        
        </ResultLayer>
        }

      </Rulletbg>


    </Column>)

      }
 

    </Container>
  );

}

export default MobileRulletEvent;

