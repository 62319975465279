import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB, Seekimage } from "../../utility/imageData";
import PcAdvertisePopup from "../../modal/PcAdvertisePopup/PcAdvertisePopup";

import StoreInfo from "../../components/StoreInfo";
import { DataContext } from "../../context/Data";


import { ReadWork } from "../../service/WorkService";
import { BetweenRow, FlexstartRow, Row } from "../../common/Row";
import Loading from "../../components/Loading";
import { FILTERITMETYPE, INCLUDEDISTANCE, LoadingType, PCMAINMENU } from "../../utility/screen";
import Position from "../../components/Position";
import { WORKNAME, WORKPOLICY } from "../../utility/work";
import { useSelector } from "react-redux";
import { Column, FlexstartColumn } from "../../common/Column";
import MobileWorkItem from "../../components/MobileWorkItem";
import Empty from "../../components/Empty";
import localforage from 'localforage';
import { getDateEx, getDateFullTime } from "../../utility/date";
import { readuserbydeviceid, Readuserbyusersid } from "../../service/UserService";
import { sleep } from "../../utility/common";
import LottieAnimation from "../../common/LottieAnimation";
import { LoadingCommunityStyle } from "../../screen/css/common";
import { GoPlus } from "react-icons/go";
import PCBanner from "../../components/PCBanner";

const Container = styled.div`
  padding:0px 24px 0px 24px;

`


const style = {
  display: "flex"
};

const Label = styled.div`
  font-size: 20px;
  line-height: 26px;
  font-family:Pretendard-SemiBold;


`
const RegistButton = styled.div`
    height: 50px;
    width: 20%;
    border-radius: 100px;
    background: #FF7e19;
    color: #fff;
    margin: 0px auto 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    font-size: 24px;
`
const Box = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction:column;
  height: 90px;
  width: 15%;
  margin-right: 10px;
  margin-bottom: 40px;
  border-radius: 15px;

`
const BoxImg = styled.div`
  background: #f9f9f9;
  border-radius: 100px;
  background: ${({clickstatus}) => clickstatus == true ? ('#dcd8d8') :('#f9f9f9;') };
  padding: 10px;
`

const MainContent = styled.div`
  width: 50%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const MainContentTxt1 = styled.div`
  line-height: 1.5;
  font-size: 34px;
  letter-spacing: -0.32px;
  font-family :Pretendard-Bold;
`
const MainContentTxt2 = styled.div`

  line-height: 1.5;
  font-size: 22px;
  letter-spacing: -0.32px;
`



/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;


const WorkItems=[
  {name : WORKNAME.HOMECLEAN, img:imageDB.house},
  {name :WORKNAME.BUSINESSCLEAN, img:imageDB.business},
  {name :WORKNAME.MOVECLEAN, img:imageDB.move},
  {name :WORKNAME.FOODPREPARE, img:imageDB.cook},
  {name :WORKNAME.ERRAND, img:imageDB.help},
  {name :WORKNAME.GOOUTSCHOOL, img:imageDB.gooutschool},
  {name :WORKNAME.BABYCARE, img:imageDB.babycare},
  {name :WORKNAME.LESSON, img:imageDB.lesson},
  {name :WORKNAME.PATIENTCARE, img:imageDB.patientcare},
  {name :WORKNAME.CARRYLOAD, img:imageDB.carry},
  {name :WORKNAME.GOHOSPITAL, img:imageDB.hospital},
  {name :WORKNAME.RECIPETRANSMIT, img:imageDB.recipe},
  {name :WORKNAME.GOSCHOOLEVENT, img:imageDB.schoolevent},
  {name :WORKNAME.SHOPPING, img:imageDB.shopping},
  {name :WORKNAME.GODOGHOSPITAL, img:imageDB.doghospital},
  {name :WORKNAME.GODOGWALK, img:imageDB.dog},
]

/**
 * 메인 데이타페이지는 
 * ! currentloading이 false 상태 일때만 보여준다
 * TODO 로딩 타입
 * ① 지역설정 타입 currentloading
 */

const PCMaincontainer =({containerStyle}) =>  {

  const {value} = useSelector((state)=> state.menu);

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [popupstatus, setPopupstatus] = useState(false);

  const [workitems, setWorkitems] = useState([]);
  const [currentloading, setCurrentloading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [menu, setMenu] = useState('');
  const [totalset, setTotalset] = useState(0);

  const _handlemenuclick =(checkmenu) =>{

    setMenu(checkmenu);

    let totalset = 0;

    if(checkmenu == WORKNAME.HOMECLEAN){
      setTotalset(WORKPOLICY.HOMECLEAN);
      totalset = WORKPOLICY.HOMECLEAN;
    }else if(checkmenu == WORKNAME.BUSINESSCLEAN){
      setTotalset(WORKPOLICY.BUSINESSCLEAN);
      totalset = WORKPOLICY.BUSINESSCLEAN;
    }else if(checkmenu == WORKNAME.MOVECLEAN){
      setTotalset(WORKPOLICY.MOVECLEAN);
      totalset = WORKPOLICY.MOVECLEAN;
    }else if(checkmenu == WORKNAME.FOODPREPARE){
      setTotalset(WORKPOLICY.FOODPREPARE);
      totalset = WORKPOLICY.FOODPREPARE;
    }else if(checkmenu == WORKNAME.GOOUTSCHOOL){
      setTotalset(WORKPOLICY.GOOUTSCHOOL);
      totalset = WORKPOLICY.GOOUTSCHOOL;
    }else if(checkmenu == WORKNAME.BABYCARE){
      setTotalset(WORKPOLICY.BABYCARE);
      totalset = WORKPOLICY.BABYCARE;
    }else if(checkmenu == WORKNAME.LESSON){
      setTotalset(WORKPOLICY.LESSON);
      totalset = WORKPOLICY.LESSON;
    }else if(checkmenu == WORKNAME.PATIENTCARE){
      setTotalset(WORKPOLICY.PATIENTCARE);
      totalset = WORKPOLICY.PATIENTCARE;
    }else if(checkmenu == WORKNAME.GOHOSPITAL){
      setTotalset(WORKPOLICY.GOHOSPITAL);
      totalset = WORKPOLICY.GOHOSPITAL;
    }else if(checkmenu == WORKNAME.RECIPETRANSMIT){
      setTotalset(WORKPOLICY.RECIPETRANSMIT);
      totalset = WORKPOLICY.RECIPETRANSMIT;
    }else if(checkmenu == WORKNAME.GOSCHOOLEVENT){
      setTotalset(WORKPOLICY.GOSCHOOLEVENT);
      totalset = WORKPOLICY.GOSCHOOLEVENT;
    }else if(checkmenu == WORKNAME.GODOGHOSPITAL){
      setTotalset(WORKPOLICY.GODOGHOSPITAL);
      totalset = WORKPOLICY.GODOGHOSPITAL;
    }else if(checkmenu == WORKNAME.GODOGWALK){
      setTotalset(WORKPOLICY.GODOGWALK);
      totalset = WORKPOLICY.GODOGWALK;
    }else if(checkmenu == WORKNAME.CARRYLOAD){
      setTotalset(WORKPOLICY.CARRYLOAD);
      totalset = WORKPOLICY.CARRYLOAD;
    }else if(checkmenu == WORKNAME.ERRAND){
      setTotalset(WORKPOLICY.ERRAND);
      totalset = WORKPOLICY.ERRAND;
    }else if(checkmenu == WORKNAME.SHOPPING){
      setTotalset(WORKPOLICY.SHOPPING);
      totalset = WORKPOLICY.SHOPPING;
    }


    setRefresh((refresh) => refresh +1);
  }

  const _handleworkselect = () =>{
    if(menu ==''){
      alert("일감 형태를 선택해주세여");
      return;
    }

    console.log("TCL: _handleworkselect -> totalset", totalset);

    navigate("/PCregist",{state :{WORKTYPE :menu, WORKTOTAL : totalset}});

  }
  
  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    setCurrentloading(currentloading);
    setSearching(searching);
  },[refresh])

  /**
   * 팝업 노출여부를 확인 한다(hongpopup1, hongpopup2, hongpopup3 를 localstorage에서 가져온다
   * ! 홍여사 요청 업무를 초기 로딩시에 구해온 데이타로 세팅 한다
   * ! 현재 페이지에서 리플레시 햇을때 서버에서 데이타를 구해 올수 있어야 한다 서비스 사용 : ReadWork()
   * 
   */
  useEffect(()=>{
    const now = moment();

    async function FetchData(){

      await sleep(1500);

      const today = moment(); // 현재 날짜
      console.log("TCL: FetchData -> today", getDateEx(today))
      const yesterday = today.clone().subtract(1, 'days'); // 1일 전 날짜
      console.log("TCL: FetchData -> yesterday", getDateEx(yesterday));
   
      const diffInDays = moment(today).diff(today, 'days');
      console.log("TCL: FetchData -> date different", diffInDays);

      let time = moment(now).subtract(1, "days").unix();
  
      const popupdate = window.localStorage.getItem("hongpopup3");
      console.log("popupdata", popupdate/1000, time);
      if (popupdate /1000 < time) {
        setPopupstatus(true);
        console.log("TCL: FetchData popup-> ",popupstatus );
      }
    } 

    FetchData();

  }, [])

  /**
   * useSelector menu 가 변경됨을 감지 함에 따라 호출되는  Hook 함수
   * 데이타를 서버로 부터 불러와서 FilterwokrItems 함수로 걸러진값을 workitems 에 설정 해준다
   */

  useEffect(()=>{

    setSearching(true);
    console.log("useEffect value=====>", value);
    async function FetchData(){


      const latitude = user.latitude;
      const longitude = user.longitude;
      const checkdistance = user.radius;

      console.log("TCL: FetchData -> useEffect=======>",user, value)

      const serverworkitems = await ReadWork({latitude, longitude,checkdistance});
      data.workitems = serverworkitems;
      datadispatch(data);

     
      if(value != [] || value != ''){
        let items = FilterWorkitems(value, serverworkitems);
        console.log("TCL: FetchData -> FilterWorkitems", items);
        setWorkitems(items);
      }else{
        setWorkitems(serverworkitems);
      }
      setSearching(false);
    }
    localforage.getItem('userconfig')
    .then(function(value) {
      console.log("TCL: listener -> App userconfig", value);
      user.latitude =value.latitude;
      user.longitude =value.longitude;
      user.radius =value.radius;
      user.deviceid = value.deviceid;
      user.address_name = value.address_name;

      user.token = value.token;
      user.phone = value.phone;
      user.userimg = value.userimg;
      user.users_id = value.users_id;
      user.radius = value.radius;



      dispatch(user);

      FetchData();
    })
    .catch(function(err) {

    });

    setRefresh((refresh) => refresh +1);
  },[value])



  const popupcallback = async () => {
    setPopupstatus(!popupstatus);
  };



  /**
   *  필터 값에 의해서 다시 데이타를 정리 해주는 함수
   *  WORKNAME.ALLWORK 인경우는 모든 값을 보여준다
   */
  function FilterWorkitems(filter, workitems){
    let items = [];
    workitems.map((data) =>{

      if(filter == WORKNAME.ALLWORK || filter == "REFRESH")
      {
        items.push(data);
      }else{
        if(data.WORKTYPE == filter){
          items.push(data);
        }
      }
    })
    return items;
  }

  /**
   * 단위 일감에서 해당 일감을 클릭햇을때 내주변으로 이동 할수 있도록 한다
   * @param 해당 work_id 와 타입을 보내주어야 한다
   */
  const _handleSelectWork = (WORK_ID) =>{
    navigate("/PCmap" ,{state :{ID :WORK_ID, TYPE : FILTERITMETYPE.HONG}});

  }


  const _handlecurrentloadingcallback = ()=> {
    setCurrentloading(false);
    setRefresh((refresh) => refresh +1);
  }

  function workfilterapply(menu, items){

    let itemsTmp = [];

    if(items == -1){
      return itemsTmp;
    }

    if(menu == WORKNAME.ALLWORK || menu ==''){
      return items;
    }
    items.map((data)=>{
      if(data.WORKTYPE == menu){
        itemsTmp.push(data);
      }
    })

    return itemsTmp;

  }
  const positioncallback = () =>{

  }
  function checkworkstep(menu){
    if(menu == WORKNAME.HOMECLEAN){
      return WORKPOLICY.HOMECLEAN;
    }else if(menu == WORKNAME.BUSINESSCLEAN){
      return WORKPOLICY.BUSINESSCLEAN;
    }else if(menu == WORKNAME.MOVECLEAN){
      return WORKPOLICY.MOVECLEAN;
    }else if(menu == WORKNAME.FOODPREPARE){
      return WORKPOLICY.FOODPREPARE;
    }else if(menu == WORKNAME.GOOUTSCHOOL){
      return WORKPOLICY.GOOUTSCHOOL;
    }else if(menu == WORKNAME.BABYCARE){
      return WORKPOLICY.BABYCARE;
    }else if(menu == WORKNAME.LESSON){
      return WORKPOLICY.LESSON;
    }else if(menu == WORKNAME.PATIENTCARE){
      return WORKPOLICY.PATIENTCARE;
    }else if(menu == WORKNAME.GOHOSPITAL){
      return WORKPOLICY.GOHOSPITAL;
    }else if(menu == WORKNAME.RECIPETRANSMIT){
      return WORKPOLICY.RECIPETRANSMIT;
    }else if(menu == WORKNAME.GOSCHOOLEVENT){
      return WORKPOLICY.GOSCHOOLEVENT;
    }else if(menu == WORKNAME.GODOGHOSPITAL){
      return WORKPOLICY.GODOGHOSPITAL;
    }else if(menu == WORKNAME.GODOGWALK){
      return WORKPOLICY.GODOGWALK;
    }else if(menu == WORKNAME.CARRYLOAD){
      return WORKPOLICY.CARRYLOAD;
    }else if(menu == WORKNAME.ERRAND){
      return WORKPOLICY.ERRAND;
    }else if(menu == WORKNAME.SHOPPING){
      return WORKPOLICY.SHOPPING;
    }


    if(menu == WORKNAME.HOMECLEAN){
      return WORKPOLICY.HOMECLEAN;
    }else if(menu == WORKNAME.BUSINESSCLEAN){
      return WORKPOLICY.BUSINESSCLEAN;
    }else if(menu == WORKNAME.MOVECLEAN){
      return WORKPOLICY.MOVECLEAN;
    }else if(menu == WORKNAME.FOODPREPARE){
      return WORKPOLICY.FOODPREPARE;
    }else if(menu == WORKNAME.ERRAND){
      return WORKPOLICY.ERRAND;
    }else if(menu == WORKNAME.GOOUTSCHOOL){
      return WORKPOLICY.GOOUTSCHOOL;
    }else if(menu == WORKNAME.BABYCARE){
      return WORKPOLICY.BABYCARE;
    }else if(menu == WORKNAME.LESSON){
      return WORKPOLICY.LESSON;
    }else if(menu == WORKNAME.PATIENTCARE){
      return WORKPOLICY.PATIENTCARE;
    }else if(menu == WORKNAME.CARRYLOAD){
      return WORKPOLICY.CARRYLOAD;
    }else if(menu == WORKNAME.GOHOSPITAL){
      return WORKPOLICY.GOHOSPITAL;
    }else if(menu == WORKNAME.RECIPETRANSMIT){
      return WORKPOLICY.RECIPETRANSMIT;
    }else if(menu == WORKNAME.GOSCHOOLEVENT){
      return WORKPOLICY.GOSCHOOLEVENT;
    }else if(menu == WORKNAME.SHOPPING){
      return WORKPOLICY.SHOPPING;
    }else if(menu == WORKNAME.GODOGHOSPITAL){
      return WORKPOLICY.GODOGHOSPITAL;
    }else if(menu == WORKNAME.GODOGWALK){
      return WORKPOLICY.GODOGWALK;
    }
  }
  return (
    <>
    {
      currentloading == true && <Loading type={LoadingType.CURRENTPOS} callback={_handlecurrentloadingcallback}/>
    }


      <PCBanner backgroundcolor={'#FFF1AA'}
      main={'동네 일잘하는 홍여사'} sub1={'모든 거래는 공식화된 계약서로'} sub2={'구해줘 홍여사가 집안일을 덜어 드립니다'} image={imageDB.honggroup} />
     
      <Container style={containerStyle}>

      {popupstatus == true ? (
        <PcAdvertisePopup type ={3}  callback={popupcallback} top={'50%'}  left={'55%'} height={'550px'} width={'500px'} image={imageDB.sample12}></PcAdvertisePopup>
      ) : null}
      <div style={{display:"flex", flexDirection:"column"}}>
        <Position type={PCMAINMENU.HOMEMENU} callback={positioncallback}/>
        <FlexstartRow style={{background:"#fff", marginBottom:"20px", alignItems:"center", width:"100%", marginTop:"10px", height:"40px",justifyContent:"center"}}>
          {
               menu != '' ? (<>
                <img src={Seekimage(menu)} style={{width:30, height:30, marginRight:20}}/>
                <Label>{menu}는 {checkworkstep(menu)}단계만 거치면 일감을 자유롭게 등록할수가 있습니다</Label>
                </>) :(<div>
                <Label>요청할 일감을 자유롭게 등록하세요</Label>
                </div>)
          }
        </FlexstartRow>
        <FlexstartColumn style={{width:'60%', margin:"10px auto 10px", justifyContent:"center"}}>
          <FlexstartRow style={{flexWrap:"wrap", width:"100%"}}>
            {
              WorkItems.map((data, index)=>(
                <Box onClick={()=>{_handlemenuclick(data.name)}} >
                  <BoxImg clickstatus={menu == data.name}><img src={data.img} style={{width:64, height:64}}/></BoxImg>
                  <div style={{ fontSize:14, marginTop:10}}>{data.name}</div>
                </Box>
              ))
            }
          </FlexstartRow>
          <RegistButton onClick={_handleworkselect}><GoPlus size={34}/> 등록</RegistButton>
        </FlexstartColumn>
      </div>
      </Container>
      <StoreInfo />
    </>


  );

}

export default PCMaincontainer;

