
export const BADGE={
    WORKER1 : '성실한 일꾼',
    WORKERDESC1 : '일거리를 5번 이상 진행 했어요',
    WORKER2 : '열일하는 홍여사',
    WORKERDESC2 : '처음 일을 시작하고 돈을 벌었어요',
    WORKER3 : '따뜻한 마음씨',
    WORKERDESC3 : '후기를 처음으로 달았어요',
    WORKER4 : '지혜의 신',
    WORKERDESC4 : '홍여사 AI를 세번이상 사용 했어요',
    WORKER5 : '사랑받는 사람',
    WORKERDESC5 : '호감표시가 5개 획득 됐어요',
    WORKER6 : '착한 귀족 ',
    WORKERDESC6 : '일감을 3개 등록했어요',
    WORKER7 : '신뢰의 여행자',
    WORKERDESC7 : '프로필 사진을 변경 하였어요',
    WORKER8 : '동네 토박이',
    WORKERDESC8 : '게시판에 글을 처음으로 등록했어요',
    WORKER9 : '지혜로운 사람',
    WORKERDESC9 : '게시판에 글을 5개 등록했어요',
    WORKER10 : '첫후기의 설레임',
    WORKERDESC10 : '처음으로 일감 후기를 작성했어요',
    WORKER11 : '신뢰의 첫걸음',
    WORKERDESC11 : '홍여사로 처음으로 등록 했어요',
    WORKER12 : '믿음직한 사람',
    WORKERDESC12 : '연속 출석 횟수를 5일 완수 하였어요',
    WORKER13 : '항상 꾸준한 사람',
    WORKERDESC13 : '연속 출석 횟수를 3일 완수 하였어요',
    WORKER14 : '댓글의 파워',
    WORKERDESC14 : '게시판에 댓글을 처음 작성했어요',
    WORKER15 : '따뜻함의 시작',
    WORKERDESC15 : '호감표시가 1개 진행 했어요',
    WORKER16 : '거래 박사',
    WORKERDESC16 : '홍여사로 공간대여로 등록 했어요',
    WORKER17 : '행운의 빛',
    WORKERDESC17 : '룰렛을 진행하고 포인트 획득 하였어요',
    WORKER18 : '꾸준함의 상징',
    WORKERDESC18 : '연속 출석 횟수를 10일 완수 하였어요',
    WORKER19 : '동네 한바퀴',
    WORKERDESC19 : '게시판에 글을 5개 등록했어요',
    WORKER20 : '이동네 후기왕',
    WORKERDESC20 : '게시판에 글을 10개 등록했어요',
    WORKER21 : '믿고 맡기는 홍여사',
    WORKERDESC21 : '일거리를 3번 이상 진행 했어요',
    WORKER22 : '동네 토박이',
    WORKERDESC22 : '프로필 대화명을 변경 하였어요',
    WORKER23 : '하트의 아름다움',
    WORKERDES23 :  '호감표시가 10개 획득 됐어요',
    WORKER24 : '이동네 개미왕',
    WORKERDESC24 : '연속 출석 횟수를 15일 완수 하였어요',
    WORKER25 : '당신이 찾는보석',
    WORKERDESC25 : '일거리를 10번 이상 진행 했어요',
    WORKER26 : '취향 존중',
    WORKERDESC26 : '홍여사 AI를 10번이상 사용 했어요',
    WORKER27 : '행운의 신',
    WORKERDESC27 : '룰렛을 진행하고 10회 포인트 획득 하였어요',
}