
import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import Button from "../../common/Button";
import { Column } from "../../common/Column";
import { Row } from "../../common/Row";
import MobileGpsLaw from "../../components/MobileGpsLaw";
import MobilePrivacyLaw from "../../components/MobilePrivacyLaw";
import MobileUseLaw from "../../components/MobileUseLaw";
import UseLaw from "../../components/UseLaw";
import { DataContext } from "../../context/Data";
import { UserContext } from "../../context/User";
import { imageDB } from "../../utility/imageData";
import { v4 as uuidv4 } from 'uuid';
import { createuser, Update_addrbyusersid, Update_userdevice } from "../../service/UserService";
import { useSleep } from "../../utility/common";
import localforage from 'localforage';
import { CountryAddress, KeywordAddress } from "../../utility/region";
import { CreateName } from "../../utility/data";
import { PROFILEIMAGE } from "../../utility/screen";
import { findWorkAndFunctionCallFromCurrentPosition, ReadWork } from "../../service/WorkService";
import { findRoomAndFunctionCallFromCurrentPosition, ReadRoom } from "../../service/RoomService";
import LottieAnimation from "../../common/LottieAnimation";
import { LoadingMainAnimationStyle } from "../../screen/css/common";
import PrivacyLaw from "../../components/PrivacyLaw";
import GpsLaw from "../../components/GpsLaw";




const Container = styled.div`
  display : flex;
  flex-direction: column;
  align-items:center;
  background-color: #f0f0f0;
  background-image: radial-gradient(circle, #000000 1px, transparent 1px);
  background-size: 50px 50px;
`

const ProgressContainer = styled.div`
  display : flex;
  flex-direction: column;
  align-items:center;
  height: 100vh;
  margin-top: 20%;
  

`
const style = {
  display: "flex"
};



const SubText = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
`

/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;

const PCSumPolicycontainer =({containerStyle}) =>  {

/** PC 웹 초기 구동시에 데이타를 로딩 하는 component
 * ① 상점정보를 불러온다
 * ② 상점정보를 불러오는 동안 로딩화면을 보여준다
 */

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [location, setLocation] = useState({ latitude: null, longitude: null });

  const [switchscreen, setSwitchscreen]= useState(false);
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);


  useEffect(()=>{
    setLoading(loading);
  },[refresh])

  /**
   * 
  
   */
  useEffect(()=>{

    async function StartProcess(){
      setRefresh((refresh) => refresh +1);
    } 

    async function FetchData(){
      StartProcess();
    } 

    FetchData();

  }, [])


  /**
   * Mobilemain으로 이동하기전에 값을 세팅 해준다
   * USER를 생성하고 userContext 값에 최종적으로 설정된다
   * ! 최종적으로 userContext 값 설정
   * ! 현재 위치에 맞는 일감 정보와 공간 대여 정보를 가져 와서 DataContext에 설정해준다
   * ! userContext 와 데이타베이스 그리고 설정 정보에 최신정보를 업데이트 해준다
   * ! userContext에 이미 구한 전화번호등 기타 정보를 세팅 하기 위해 데이타 베이스관련작업을 먼저 한다
   * ! userContext 
   *   1) latitude
   *   2) longidue
   *   3) address 기타 정보
   *   4) phone, nickname, deiviceid, users_id
   */
   
  const _handleMain = async() =>{
    console.log("TCL: _handleMain -> _handleMain");

    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const { latitude, longitude } = pos.coords;
  
 
        // Geocoder를 사용하여 좌표를 주소로 변환
        const geocoder = new kakao.maps.services.Geocoder();
        geocoder.coord2Address(longitude, latitude, async (result, status) => {
          if (status === kakao.maps.services.Status.OK) {
            const address = result[0].address;

            user.address_name = address.address_name;
            user.latitude  = latitude;
            user.longitude = longitude;
         
            
            dispatch(user);
    

            // 설정된 거리 내외에 현재 위치에 존재 하는 데이타가 있습니까?

            const addr = KeywordAddress(user.address_name);
            const currentlatitude = latitude;
            const currentlongitude = longitude;
       
            const workfunctioncall = await findWorkAndFunctionCallFromCurrentPosition({addr, currentlatitude, currentlongitude});
           // const roomfunctioncall= await findRoomAndFunctionCallFromCurrentPosition({addr, currentlatitude, currentlongitude});

            let uniqueId = uuidv4();
            let nickname = CreateName(CountryAddress(user.address_name));
            const USERINFO={
              deviceid : uniqueId,
              token : user.token,
              phone : user.phone,
              latitude : user.latitude,
              longitude : user.longitude,
              address_name : user.address_name,
              nickname : nickname,
              userimg : PROFILEIMAGE,
              worker : false,
        
            }
            const DEVICEID = uniqueId;
            const TOKEN = user.token;
            const users_id = await createuser({USERINFO, DEVICEID, TOKEN});
        
            user.users_id = users_id;
            user.deviceid = uniqueId;
        
            user.nickname =  nickname;
            user.userimg = PROFILEIMAGE;
            dispatch(user);
   
            const ADDRINFO={
              ADDR : user.address_name,
              LATITUDE : user.latitude,
              LONGITUDE : user.longitude,
              RADIUS : 5,
            }
            const USERS_ID = user.users_id;
            const updateaddr = await Update_addrbyusersid({ADDRINFO, USERS_ID});

        
            localforage.setItem('userconfig', user)
            .then(async function(value) {
              console.log("TCL: userconfig save", value);

              const checkdistance = user.radius;

              const workitems = await ReadWork({latitude, longitude,checkdistance});
           
              const roomitems = await ReadRoom({latitude, longitude,checkdistance});
     
    
              data.workitems = workitems;
              data.roomitems = roomitems;
              datadispatch(data);
              dispatch(user);
             setLoading(false);
          
             navigate("/PCmain");
        
            })
            .catch(function(err) {
              console.error('TCL : userconfig 데이터 저장 실패:', err);
            });
        
         

  

  
          }else{
            alert(status);
          }
        });
  
      },
      (err) => {
        console.error(err);
        alert(err);
      },
      {
          enableHighAccuracy: false,  // 높은 정확도 비활성화
          timeout: 20000,             // 최대 20초 대기
          maximumAge: 0              // 캐시된 위치 사용 안 함
      }
    );


    

  }

 
  return (
    <Container style={containerStyle}>
      {
      loading == true ? (    
        <ProgressContainer>
          <LottieAnimation animationData={imageDB.loadinglarge}
          width={"100px"} height={'100px'}/>
        </ProgressContainer>

        
        
        ):(
          <>
          <UseLaw containerStyle={{width:"50%", background:"#fff", margin:"0 auto"}}/>
          <PrivacyLaw containerStyle={{width:"50%", background:"#fff", margin:"0 auto"}}/>
          <GpsLaw containerStyle={{width:"50%", background:"#fff", margin:"0 auto"}}/>
    
          <Column style={{width:"30%", margin:"0 auto"}}>
            <SubText>이용약관, 개인정보 처리지침, 위치 서비스 이용약관에 대해</SubText>
            <Button containerStyle={{border: 'none', fontSize:22, margin: '0px auto 20px', height:'50px'}} onPress={_handleMain} height={'44px'} width={'85%'} radius={'4px'} bgcolor={'#FF7125'} color={'#fff'} 
            text={'모두 동의합니다 '}/>
          </Column>
          </>
 
        )
}
    
    
    
    </Container>
  );

}

export default PCSumPolicycontainer;

