
import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import Button from "../common/Button";
import LottieAnimation from "../common/LottieAnimation";
import { UserContext } from "../context/User";
import MobileMapPopup from "../modal/MobileMapPopup/MobileMapPopup";
import MobileWorkMapPopup from "../modal/MobileMapPopup/MobileWorkMapPopup";
import MobileSuccessPopup from "../modal/MobileSuccessPopup/MobileSuccessPopup";
import { CreateChat, ReadChat } from "../service/ChatService";

import { Readuserbyusersid } from "../service/UserService";
import { ReadWorkByIndividually } from "../service/WorkService";
import { getDateFullTime } from "../utility/date";
import { imageDB, Seekimage } from "../utility/imageData";
import { REQUESTINFO } from "../utility/work";




const Container = styled.div`
  width:90%;
  max-height: 500px;
  overflow-y: auto;
`
const style = {
  display: "flex"
};

const ResultContent = {
  width: '160px',
  fontSize: '14px',
  fontFamily: 'Pretendard-Light',
  lineHeight: 2,
  outline:"none",
  resize :"none",
  border:"none",
 
}

const LoadingAnimationStyle={
  zIndex: 11,
  position: "absolute",
  top: "40%",
  left: "35%"
}

const MobileWorkReport =({containerStyle, messages, WORK_ID, WORKTYPE, WORK_STATUS}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [popupstatus, setPopupstatus] = useState(false);

  const [latitude, setLatitude] = useState('');
  const [longitudie, setLongitude] = useState('');
  const [worktype, setWorktype] = useState(WORKTYPE);


  const [supporterwork, setSupporterwork] = useState(false);
  const [ownerwork, setOwnerwork] = useState(false);
  const [closework, setClosework] = useState(false);
  const [currentloading, setCurrentloading] = useState(true);

  const [supportWorkSuccess, setSupportWorkSuccess] = useState(false);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{
    setPopupstatus(popupstatus);  
    setSupporterwork(supporterwork);
    setOwnerwork(ownerwork);
    setClosework(closework);
    setSupportWorkSuccess(supportWorkSuccess);
    setCurrentloading(currentloading);
  }, [refresh])

  useEffect(()=>{
      async function FetchData(){
       
      // 마감되었는지 확인 필요
      if(WORK_STATUS == 1){
          setClosework(true);
       }

       // 자신의 소유인지 확인 필요
       const WORK_INFO = await ReadWorkByIndividually({WORK_ID});
       if(WORK_INFO.USERS_ID == user.users_id){
          setOwnerwork(true);
       }

       // 지원 햇는지 확인 필요
       const USERS_ID = user.users_id;
       const chatitems = await ReadChat({USERS_ID});


       if(chatitems.length > 0){
        const FindIndex = chatitems.findIndex(x=>x.INFO.WORK_ID == WORK_ID);
        if(FindIndex != -1){
         if(chatitems[FindIndex].SUPPORTER_ID == USERS_ID){
           setSupporterwork(true);
         }
        }
       }
  
       setCurrentloading(false);
      }
      FetchData();
  }, [])
  const _handleReset = () =>{

  }

  /**
   * WORK_ID를가지고 
   * 1) WORK 정보를 가져돈다
   * 2) 지원자 의 정보를 가져온다
   */
  const _handleReqComplete = async(WORK_ID) =>{
  
     const WORK_INFO = await ReadWorkByIndividually({WORK_ID});

    

     let USERS_ID = WORK_INFO.USERS_ID;
     let OWNER = await Readuserbyusersid({USERS_ID});

     console.log("TCL: _handleReqComplete -> WORK_INFO", USERS_ID, OWNER)
     if(OWNER == -1){
       return;
     }
     const OWNER_ID = OWNER.USERS_ID;

     USERS_ID = user.users_id;;
     const SUPPORTER = await Readuserbyusersid({USERS_ID});
     const SUPPORTER_ID = SUPPORTER.USERS_ID;
     const createchat = await CreateChat({OWNER, OWNER_ID,  SUPPORTER, SUPPORTER_ID, WORK_INFO});

     setSupportWorkSuccess(true);
     setRefresh((refresh) => refresh +1);


  }

  const popupcallback = async () => {
    setPopupstatus(!popupstatus);
    setRefresh((refresh) => refresh +1);
  };

  const supportsuccesscallback = async () =>{
    setSupportWorkSuccess(false);
    navigate("/Mobilechat");
    setRefresh((refresh) => refresh +1);
  }

  const _handleMapview= (lat, long, worktype)=>{

    setPopupstatus(true);
    setLatitude(lat);
    setLongitude(long);
    setWorktype(worktype);
    setClosework(closework);
    setSupporterwork(supporterwork);
    setOwnerwork(ownerwork);

    setRefresh((refresh) => refresh +1);

  }

 
  return (

    <Container style={containerStyle}>

      {
        popupstatus == true && <MobileWorkMapPopup callback={popupcallback} latitude={latitude} longitude={longitudie}
        top={'30%'}  left={'10%'} height={'280px'} width={'280px'} name={worktype} markerimg={Seekimage(worktype)}
        />
      }

      {
        supportWorkSuccess == true && <MobileSuccessPopup callback={supportsuccesscallback} content ={'일감에 정상적으로 지원되었습니다'} />
      }

      {
        currentloading == true ? (<LottieAnimation containerStyle={LoadingAnimationStyle} animationData={imageDB.loadinglarge}
          width={"100px"} height={'100px'}/>) :(<>
          <table class="workreport-table" style={{  margin: '10px auto', borderTop: "1px solid #434343"}}>      
          <tbody>
            {
              messages.map((data)=>(
                <>
                {
                data.type =='response' &&
                <tr>
                <td>{data.requesttype}</td>
                <td>
                 <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                 {
                   (data.requesttype == REQUESTINFO.COMMENT && data.result =='') ? (
                    <div> {'별도 요구사항 없음'}</div>):(
                   <div>{
                    data.requesttype == REQUESTINFO.REQDATE ? (<div>{getDateFullTime(data.result)}</div>) :
                    (<div>{data.result}</div>)
                    
                  }</div>
                 )
                 }  

                 {
                   data.requesttype == REQUESTINFO.CUSTOMERREGION &&
                   <div  onClick={()=>{_handleMapview(data.latitude,data.longitude, messages.WORKTYPE)}}><img src={imageDB.movegps} style={{width:36}}/> </div>
                 }
                 </div>
                </td>
                </tr>
                }
                </>                  
              ))
            }
          </tbody>
           </table>

          <div style={{display:"flex", flexDirection:"row", margin:'10px auto', width:'90%',justifyContent: "center",position:"absolute", bottom:10 }}>
            {
              closework == true ? (<Button containerStyle={{border: 'none', fontSize:16, marginTop:10, fontWeight:600}} height={'44px'} width={'100%'} radius={'4px'} bgcolor={'#EDEDED'} color={'#999'} text={'이미 마감된 일감'}/>)
              :(
                <>
                  {
                    supporterwork == true && <Button containerStyle={{border: 'none', fontSize:16, marginTop:10, fontWeight:600}} height={'44px'} width={'100%'} radius={'4px'} bgcolor={'#EDEDED'} color={'#999'} text={'이미 지원한 일감'}/>
                  }

                  {
                    ownerwork == true && <Button containerStyle={{border: 'none', fontSize:16, marginTop:10, fontWeight:600}} height={'44px'} width={'100%'} radius={'4px'} bgcolor={'#EDEDED'} color={'#999'} text={'본인이 등록한 일감'}/>
                  }
                  {
                    (supporterwork ==false && ownerwork == false) && <Button containerStyle={{border: 'none', fontSize:16, marginTop:10, fontWeight:600}} onPress={()=>{_handleReqComplete(WORK_ID)}} height={'44px'} width={'100%'} radius={'4px'} bgcolor={'#FF7125'} color={'#fff'} text={'지원하기'}/>

                  }
                </>
              )
            }
          </div>
          </>)
      }


    </Container>
  );

}

export default MobileWorkReport;

