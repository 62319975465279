
import { Table } from "@mui/material";
import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { FlexEndRow, FlexstartRow, Row } from "../common/Row";
import { UserContext } from "../context/User";

import "./mobile.css"
import { imageDB } from "../utility/imageData";
import LottieAnimation from "../common/LottieAnimation";
import { useSleep } from "../utility/common";
import CurrentMap from "./CurrentMap";
import PCMapPopup from "../modal/PcMapPopup/PcMapPopup";
import { Column, FlexstartColumn } from "../common/Column";
import { DataContext } from "../context/Data";
import MobileMapPopup from "../modal/MobileMapPopup/MobileMapPopup";
import { ReadTourFestival } from "../service/LifeService";
import ResultLabel from "../common/ResultLabel";
import { autoBatchEnhancer } from "@reduxjs/toolkit";
import { ReadCommunitySummary } from "../service/CommunityService";
import TimeAgo from 'react-timeago';
import { getFullTime } from "../utility/date";
import koreanStrings from "react-timeago/lib/language-strings/ko";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { LoadingCommunityStyle } from "../screen/css/common";
import BoardBox from "./BoardBox";
import { PCCOMMNUNITYMENU } from "../utility/screen";
import PCBanner from "./PCBanner";

const formatter = buildFormatter(koreanStrings); 

const Container = styled.div`

  margin : 0 auto;
  display : flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
  scrollbar-width: none;
  overflow : auto;
  width: 90%;
  background :#fff;
  min-height:600px;


`
const style = {
  display: "flex"
};






const Inputstyle ={

  background: '#FFF',
  borderRadius:'5px',
  fontSize: '16px',
  padding: '0px 16px 0px 16px',
  height : '40px',
  border : "1px solid #FF7125",


}


const  SearchLayer = styled.div`
  width: 90%;
  margin : 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: sticky;
  top: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  

`

const BoxItem = styled.div`
  padding: 0px 0px 30px;
  margin-bottom: 30px;
  color: #333;
  line-height: 1.8;
  font-family: "Pretendard-Regular";
  width: 30%;


`
const BoxLabel = styled.div`
  font-family: "Pretendard-SemiBold";
  font-size:16px;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  width:100%;


`

const BoxContent = styled.div`
  font-family: "Pretendard-Regular";
  font-size:14px;

`
const BoxWrite = styled.div`
  display : flex;
  flex-direction : row;
  justify-content: flex-start;
  align-items: center;
  width : 100%;
  font-size :14px;
`

const BoxImage = styled.div`
  margin-top:5px;
`

const LoadingStyle={
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  top: "300px",
  position:"absolute"
}
const MapbtnStyle={
  background: "#ff",
  padding: "0px 20px",
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: '50px',
  color: '#333',
  border :" 1px solid #c3c3c3",
  height: '38px',
  fontSize:'16px',
  fontFamily:'Pretendard-SemiBold',
  width:'30%',
  margin :'20px auto 0px',
}

const Taglabel = styled.div`
  font-family: "Pretendard";
  font-size:12px;
  margin-right:10px;
  min-width:50px;
  display : flex;
  align-items: center;
  justify-content: center;
  background-color:#FFF5F5;
  color :#FF2121;
  border-radius: 5px;

`

const TagData = styled.div`
  font-family: "Pretendard-Light";
  font-size:14px;

  color :#131313;
`
const Item = styled.div`
  margin: 5px 0px;
  display:flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:center;
`
const TagButton = styled.div`
  border: 1px solid #ededed;
  padding: 10px;
  background: ${({select}) => select == true ?('#ff7e19'):('#f6f6f6')};
  color: ${({select}) => select == true ?('#fff'):('#131313')};
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`

const MainContent = styled.div`
  width: 50%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const MainContentTxt1 = styled.div`
  line-height: 1.5;
  font-size: 34px;
  letter-spacing: -0.32px;
  font-family :Pretendard-Bold;
`
const MainContentTxt2 = styled.div`

  line-height: 1.5;
  font-size: 22px;
  letter-spacing: -0.32px;
`

const PCLifeBoard =({containerStyle}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);


  const [displayitems1, setDisplayitems1] = useState([]);
  const [displayitems2, setDisplayitems2] = useState([]);
  const [displayitems3, setDisplayitems3] = useState([]);

  const [loading, setLoading] = useState(true);

  const [selectmenu, setSelectmenu] = useState(PCCOMMNUNITYMENU.ALLITEM);


  const BoardItems =[
    PCCOMMNUNITYMENU.ALLITEM,
    PCCOMMNUNITYMENU.DAILYITEM,
    PCCOMMNUNITYMENU.PARENTMARRIAGEITEM,
    PCCOMMNUNITYMENU.PASSIONITEM,
    PCCOMMNUNITYMENU.INTERIORITEM,
    PCCOMMNUNITYMENU.RECIPEITEM,
    PCCOMMNUNITYMENU.CULTUREITEM,
    PCCOMMNUNITYMENU.HOBBYITEM,

  ]

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{
    setDisplayitems1(displayitems1);
    setDisplayitems2(displayitems2);
    setDisplayitems3(displayitems3);
    setLoading(loading);
    setSelectmenu(selectmenu);

  },[refresh])

  async function FetchData(type){
    const communiytitems = await ReadCommunitySummary();
    console.log("TCL: FetchData -> communiytitems", communiytitems);

    let items1 = [];
    let items2 = [];
    let items3 = [];

    if(type == PCCOMMNUNITYMENU.ALLITEM){
      communiytitems.map((data, index)=>{
        if(index % 3 == 0){

          items1.push(data);
        }
        else if(index % 3 ==1){
          items2.push(data);       
        }
        else if(index % 3 ==2){
          items3.push(data);
        }
      })
    }else {

      let items = [];
      communiytitems.map((data, index)=>{
        if(data.COMMUNITYCATEGORY == type){
          items.push(data);
        }
      });

      items.map((data, index)=>{
        if(index % 3 == 0){
          items1.push(data);
        }
        else if(index % 3 ==1){
          items2.push(data);       
        }
        else if(index % 3 ==2){
          items3.push(data);
        }
      })
    }

    setDisplayitems1(items1);
    setDisplayitems2(items2);
    setDisplayitems3(items3);
 
    setLoading(false);
  }


  useEffect(()=>{
      FetchData(PCCOMMNUNITYMENU.ALLITEM);
  }, [])


  const _handleSelectMenu = (data)=>{
    setSelectmenu(data);
    FetchData(data);
    setRefresh((refresh) => refresh +1);
  }


  return (
    <>


      <PCBanner main={'우리 동네 홍여사 게시판'} button={true} backgroundcolor={"#f7f1eb"} sub1={'유익한 정보, 좋은 정보 주고 받으세여'} 
      top={'60px'}
      sub2={'구해줘 홍여사 세상과 연결되세요'} image={imageDB.shopping}  imagewidth={'180px'} imageheight={'180px'} />

      <Container style={containerStyle}>     
      <Column style={{marginTop:10,width:"95%",margin:"0 auto",}}>
              {
                loading == true && <LottieAnimation containerStyle={LoadingCommunityStyle} animationData={imageDB.loading}
                  width={"100px"} height={'100px'}/>
              }

              <Row style={{flexWrap: "wrap", width:"100%", margin:"20px auto", justifyContent:"flex-start", alignItems:"flex-start"}}>
                {
                  BoardItems.map((data)=>(
                    <TagButton onClick={()=>{_handleSelectMenu(data)}}
                     select ={data == selectmenu}
                    >{data}</TagButton>
                  ))
                }

              </Row>


              <Row style={{alignItems:"unset"}}>

                <div style={{ height:'100%', width:'33%'}}>
                {
                  displayitems1.map((data, index)=>(
                    <BoardBox item = {data}></BoardBox>
                  ))
                }
                </div>
                <div style={{ height:'100%', width:'33%'}}>
                {
                  displayitems2.map((data, index)=>(
                    <BoardBox item = {data}></BoardBox>
                  ))
                }
                </div>
                <div style={{height:'100%', width:'33%'}}>
                {
                  displayitems3.map((data, index)=>(
                    <BoardBox item = {data}></BoardBox>
                  ))
                }
                </div>


              </Row>

        
      </Column>
      </Container>
    </>
  );
}

export default PCLifeBoard;

