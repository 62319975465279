import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../../context/User";
import moment from "moment";
import { imageDB } from "../../../utility/imageData";

import { AroundRow, BetweenRow, FlexstartRow, Row } from "../../../common/Row";
import { Column, FlexstartColumn } from "../../../common/Column";

import Button from "../../../common/Button";
import { DataContext } from "../../../context/Data";

import { readuserbydeviceid, Update_attendancebyusersid } from "../../../service/UserService";
import { getDateEx, getDateFullTime } from "../../../utility/date";
import { useDispatch } from "react-redux";
import { ALLREFRESH } from "../../../store/menu/MenuSlice";
import { sleep } from "../../../utility/common";
import LottieAnimation from "../../../common/LottieAnimation";

const Container = styled.div`
 

`
const style = {
  display: "flex"
};

const EventProcessTag = styled.div`
  background: #000;
  width: 100px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  color: #fff;
  height: 40px;
  align-items: center;

`

const EventTitle = styled.div`
  font-size: 20px;
  line-height: 60px;

  border-bottom: 1px solid #ddd;
  width : 85%;
  font-weight:500;

`

const CheckStatus = styled.div`
  text-align: center;
  background-color: #f4f4fe;
  padding-top: 20px;
  width: 85%;
  margin : 10px auto;

`

const AttendanceCheckLabel = styled.div`
  font-size: 25px;
  color: #3c4cb2;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -1.5px;
`
const AttendanceCheckDay = styled.div`
    font-size: 40px;
    margin-top: 15px;
    font-weight: 700;
    line-height: 70px;
    margin-bottom: 44px;
    color :#000;
`

const AttendanceCheckDesc = styled.div`
  font-size: 18px;
  color: #797979;
  line-height: 34px;
  margin-bottom: 20px;
  padding: 0px 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
`

const AttendanceEvent= [
{day :1, check : false, checkday:""},
{day :2, check : false, checkday:""},
{day :3, check : false, checkday:""},
{day :4, check : false, checkday:""},
{day :5, check : false, checkday:""},
{day :6, check : false, checkday:""},
{day :7, check : false, checkday:""},
{day :8, check : false, checkday:""},
{day :9, check : false, checkday:""},
{day :10, check : false, checkday:""},
{day :11, check : false, checkday:""},
{day :12, check : false, checkday:""},
{day :13, check : false, checkday:""},
{day :14, check : false, checkday:""},
{day :15, check : false, checkday:""},

]

const CheckDate = styled.div`
  position: absolute;
  padding-top: 25px;
  color: #554f4f;
  font-size:12px;
`

const LoadingAnimationStyle={
  zIndex: 11,
  position: "absolute",
  top: "40%",
  left: "35%"
}

const Attdendanceday = 3;

const MobileAttendanceEvent =({containerStyle}) =>  {
  const reduxdispatch = useDispatch();

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [checkitems, setCheckitems] = useState([]);
  const [animatecomplete, setAnimatecomplete] = useState(false);
  const [check, setCheck] = useState(0);
  const [enable, setEnable] = useState(true);
  const [currentloading, setCurrentloading] = useState(true);


  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    setAnimatecomplete(animatecomplete);
    setCheck(check);
    setEnable(enable);
    setCurrentloading(currentloading);
  },[refresh])


  async function FetchData(){


  
    const DEVICEID = user.deviceid;
    const userdata = await readuserbydeviceid({DEVICEID});
    const today = moment(); // 현재 날짜
    console.log("TCL: FetchData -> today", today);
    console.log("TCL: FetchData -> today", getDateFullTime(today))
    const pastDate = today.clone().subtract(1, 'days'); // 1일 전 날짜
    console.log("TCL: FetchData -> pastDate", moment(pastDate).valueOf())
    console.log("TCL: FetchData -> pastDate", getDateEx(pastDate));

    userdata.CHECKDATE.map((data, index) =>{
      AttendanceEvent[index].check = true;
      AttendanceEvent[index].checkday = data;
    })


  if(userdata.CHECKDATE.length > 0){
  // const diffInDays = moment(pastDate).diff(userdata.CHECKDATE[userdata.CHECKDATE.length -1], 'days');
  // 오늘 체크한경우
    if(getDateEx(userdata.CHECKDATE[userdata.CHECKDATE.length -1]) == getDateEx(today)){
      setEnable(false);
    }else if(getDateEx(userdata.CHECKDATE[userdata.CHECKDATE.length -1]) == getDateEx(pastDate)){
      console.log("TCL: FetchData -> getDateEx(pastDate)", getDateEx(pastDate));
      console.log("TCL: FetchData -> (getDateEx(userdata.CHECKDATE[userdata.CHECKDATE.length -1])", getDateEx(userdata.CHECKDATE[userdata.CHECKDATE.length -1]));
      setEnable(true);
    }else{
      setEnable(false); 
    }

    setCheck(userdata.CHECKDATE.length);

  }else{
    setEnable(true);
  }
  setCheckitems(AttendanceEvent);
  setCurrentloading(false);
  setRefresh((refresh) => refresh +1);
}

  /**
   * 초기 페이지 진입시에는 context에 있는 정보를 가져온다
  
   */

  useEffect(()=>{
   FetchData();
  }, [])


  const _hanldlecheck = async() =>{

    setEnable(false);
    setRefresh((refresh) => refresh +1);

    const DEVICEID = user.deviceid;
    const userdata = await readuserbydeviceid({DEVICEID});

    console.log("TCL: _hanldlecheck -> user", user, userdata);

    const today = moment(); // 현재 날짜

    const DATE = getDateEx(today);
    const USERS_ID = user.users_id;

    Update_attendancebyusersid({DATE, USERS_ID}).then(async ()=>{
      
      await sleep(1000);
      reduxdispatch(ALLREFRESH());
   
      FetchData();
    })

 
  }




  return (
    <Container style={containerStyle}>
      {currentloading == true ? (<LottieAnimation containerStyle={LoadingAnimationStyle} animationData={imageDB.loadinglarge}
          width={"100px"} height={'100px'}/>):(  <Column margin={'0px auto;'} width={'100%'} style={{background:"#fff"}} >
          <EventTitle>
            <div style={{marginTop:30}}>
              <EventProcessTag>진행중</EventProcessTag>
            </div>
            <BetweenRow>
              <EventTitle style={{textAlign: "left"}}>{'출석 체크 이벤트'}</EventTitle>
              <EventTitle style={{textAlign: "right"}}>{'~ 행사종료시 까지'}</EventTitle>
            </BetweenRow>
          </EventTitle>
  
          <FlexstartRow style={{justifyContent:"center", marginTop:15}}>
            <CheckStatus>
              <AttendanceCheckLabel>나의 누적 출석체크 현황</AttendanceCheckLabel>
              <AttendanceCheckDay>{check}일</AttendanceCheckDay>
              <AttendanceCheckDesc>15일 누적 출석이 완료되면 15000point를 드립니다. 15000point를 획득하면 나의 누적 현황이 초기화 되며,이벤트를 다시 참여 할 수 있습니다.</AttendanceCheckDesc>
              <Row style={{flexWrap:"wrap", margin:"40px 10px"}}>
              {
                checkitems.map((data, index)=>(
                  <Column style={{width:'33%', height:150}}>
                    <div>{data.day}일</div>
                    <CheckDate>{data.checkday}</CheckDate>
                    {
                      animatecomplete == false && <>
                      {
                        data.check == true  ? (<img src={imageDB.sample31} style={{width:90, height:90}}/>):(
                          <img src={imageDB.sample32} style={{width:90, height:90}}/>
                        )
                      }
                      </>
                    }
                    {
                      animatecomplete == true && <>
                      {
                        data.check == true  ? (<img src={imageDB.sample33} style={{width:90, height:90}}/>):(
                          <img src={imageDB.sample32} style={{width:90, height:90}}/>
                        )
                      }
                      </>
                    }
                   
                  </Column>
                ))
              }
              </Row>
  
              {
                enable == true ? (<Button onPress={_hanldlecheck} height={'45px'} width={'90%'} radius={'5px'} bgcolor={'#000'} color={'#fff'} text={'출석체크'}
                containerStyle={{fontSize: "22px", fontWeight:900, marginBottom:50, margin: "20px auto"}}/>) :(<Button height={'45px'} width={'90%'} radius={'5px'} bgcolor={'#ededed'} color={'#666'} text={'출석체크'}
                containerStyle={{fontSize: "22px", fontWeight:900, marginBottom:50, margin: "20px auto"}}/>)
              }
              
           
            </CheckStatus>
  
          </FlexstartRow>
  
        </Column>)
      }


    </Container>
  );

}

export default MobileAttendanceEvent;

