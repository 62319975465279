import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import LazyImage from "../common/LasyImage";
import { FlexstartRow } from "../common/Row";
import { UserContext } from "../context/User";
import { imageDB } from "../utility/imageData";
import { CHATIMAGETYPE } from "../utility/screen";
import "./ProfileImage.css"



const Container = styled.div`

`
const style = {
  display: "flex"
};

const ImageLayout = styled.div`
    background: #fff;
    height: 50px;
    border-radius: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items:center;
   
`
const Tag = styled.div`

  background: ${({OWNER}) => OWNER == true ? ('#FF7125') :('#25a3ff')};
  position: relative;
  height: 20px;
  width: 60px;
  top: 35px;
  left: 10px;
  color: #fff;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

`





const ChatprofileImage =({containerStyle, source, OWNER, TYPE}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);


 
  return (

    <Container style={containerStyle}>
      <ImageLayout>
       <LazyImage src={source}
       containerStyle={{height:"50px",width:"50px",
        borderRadius:"100px",objectFit:"cover", backgroundColor: '#e0e0e0', display:"flex", justifyContent:"center" }}
       />
      </ImageLayout>
      {/* <Tag OWNER={OWNER}>
        {OWNER == true ? (<span>{TYPE}의뢰</span>) :(<span>{TYPE}지원</span>)}
      </Tag> */}
    </Container>
  );

}

export default ChatprofileImage;

