import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { Row } from "../../common/Row";
import { UserContext } from "../../context/User";
import { imageDB } from "../../utility/imageData";


import { CONFIGMOVE } from "../../utility/screen";

import MobileUseLaw from "../../components/MobileUseLaw";
import MobilePrivacyLaw from "../../components/MobilePrivacyLaw";
import MobileGpsLaw from "../../components/MobileGpsLaw";

import MobileEventView from "../../components/config/event/MobileEventView";
import MobileWorkerInfo from "../../components/config/regist/MobileWorkerInfo";
import MobileProfileConfig from "../../components/config/profile/MobileProfileConfig";
import MobileProfileBadge from "../../components/config/profile/MobileProfileBadge";
import MobileProfileName from "../../components/config/profile/MobileProfileName";
import MobileMapConfig from "../../components/config/configuration/MobileMapConfig";
import MobileAlarmConfig from "../../components/config/configuration/MobileAlarmConfig";
import PCProfileName from "../../components/config/profile/PCProfileName";
import PCProfileConfig from "../../components/config/profile/PCProfileConfig";
import PCWorkerInfo from "../../components/config/regist/PCWorkerInfo";
import MobileRegisterWork from "../../components/config/activity/MobileRegisterWork";
import MobileSupportWork from "../../components/config/activity/MobileSupportWork";
import MobileRegisterRoom from "../../components/config/activity/MobileRegisterRoom";
import MobileSupportRoom from "../../components/config/activity/MobileSupportRoom";
import PCRegisterWork from "../../components/config/activity/PCRegisterWork";
import PCSupportWork from "../../components/config/activity/PCSupportWork";
import PCRegisterRoom from "../../components/config/activity/PCRegisterRoom";
import PCSupportRoom from "../../components/config/activity/PCSupportRoom";
import PCMapConfig from "../../components/config/configuration/PCMapConfig";
import PCAlarmConfig from "../../components/config/configuration/PCAlarmConfig";




const Container = styled.div`
  background-color : #f7f7f7;
  width:100%;
  margin:0 auto;
  background-color: #f0f0f0;
  background-image: radial-gradient(circle, #000000 1px, transparent 1px);
  background-size: 50px 50px;


`
const BoxItem = styled.div`

  background: #fff;
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction : column;
  width: 85%;
  margin: 10px auto;
  border-radius: 10px;
  padding: 10px;
`
const Name = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 16px;
  padding-left:5px;
`
const ProfileConfigBtn = styled.div`
  background: #f9f9f9;
  padding: 10px;
  font-family: 'Pretendard-SemiBold';
  font-size: 12px;
`
const RegistHong = styled.div`
  border: 1px solid rgb(237, 237, 237);
  height: 70%;
  margin: 10px 0px;
  border-radius: 10px;
`
const RegistLayer = styled.div`
  height: 45px;
  background: #ff71255c;
  margin: 10px;
  border: 2px dotted;
  border-radius: 10px;
  display:flex;
  flex-direction : row;
  justify-content : center;
  align-items:center;
`
const RegistLayerContent = styled.div`
  color: #ff7125;
  font-weight: 900;
  font-family: 'Pretendard-Bold';
`
const Label = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 16px;
  padding: 20px 10px;
`
const SubLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-left: 10px;
`

const SubLabelContent = styled.div`
  font-family: 'Pretendard-Light';
  font-size: 16px;
  padding: 20px 10px;
`




const PCConfigContentcontainer =({containerStyle, name}) =>  {

  const { dispatch, user } = useContext(UserContext);
  console.log("TCL: MobileConfigcontainer -> user", user)
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    async function FetchData(){
    } 
    FetchData();
  }, [])
  useEffect(()=>{

  },[refresh])

 
  return (

    <Container style={containerStyle}>
        <div style={{width:"30%", margin:"0 auto", background:"#f0f0f0",paddingTop:50}}>
        {
          name == CONFIGMOVE.EVENTVIEW && <MobileEventView/>
        }

        {
          name == CONFIGMOVE.LAWPOLICY && <MobileUseLaw/>
        }

        {
          name == CONFIGMOVE.LAWPRIVACY && <MobilePrivacyLaw/>
        }

        {
          name == CONFIGMOVE.LAWGPS && <MobileGpsLaw/>
        }


        {
          name == CONFIGMOVE.WORKERINFO && <PCWorkerInfo/>
        }

        {
          name == CONFIGMOVE.PROFILECONFIG && <PCProfileConfig/>
        }

        {
          name == CONFIGMOVE.PROFILENAME && <PCProfileName/>
        }

 
        {
          name == CONFIGMOVE.MAPCONFIG && <PCMapConfig/>
        }

        {
          name == CONFIGMOVE.ALARMCONFIG && <PCAlarmConfig/>
        }

        {
          name == CONFIGMOVE.REGISTERWORK && <PCRegisterWork/>
        }
        {
          name == CONFIGMOVE.SUPPORTWORK && <PCSupportWork/>
        }
        {
          name == CONFIGMOVE.REGISTERROOM && <PCRegisterRoom/>
        }
        {
          name == CONFIGMOVE.SUPPORTROOM && <PCSupportRoom/>
        }


        </div>
    </Container>
  );

}

export default PCConfigContentcontainer;

