import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import Empty from "../../components/Empty";
import { UserContext } from "../../context/User";
import { IoIosCloseCircle } from "react-icons/io";
import { Column } from "../../common/Column";
import { BetweenRow, Row } from "../../common/Row";

const Container = styled.div`
padding:50px 0px 0px 0px;
width: 100%;
margin : 0 auto;
height: calc(100vh - 50px);
scrollbar-width: none; // 스크롤바 안보이게 하기
overflow-x: hidden; /* X축 스크롤을 숨깁니다. */
`
const style = {
  display: "flex"
};


const PopupGuide = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #f2f2f2;
  padding: 20px;
  color: #131313;
  border-radius: 10px;
  height:170px;

`

const ULITEM = styled.ul`
  padding-left: 15px;
  list-style-type: disc;
  line-height: 2;
  margin-top: 10px;
  font-family:Pretendard-Light;
  font-size:12px;

`


const Hongcontainer =({containerStyle}) =>  {

  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [guideclose, setGuideclose] = useState(true);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    async function FetchData(){
    } 
    FetchData();
  }, [])
  useEffect(()=>{
    setGuideclose(guideclose);
  },[refresh])

 
  const _handleClose =() =>{
    setGuideclose(false);
    setRefresh((refresh) => refresh +1);
  }



  return (

    <Container style={containerStyle}>
      <Empty content = "노출조건에 맞는 홍여사가 존재 하지 않습니다" fontsize="14px"
              containerStyle={{marginTop:"150px"}}
              />

      {
          guideclose == true && <PopupGuide>
          <Column>
          <BetweenRow style={{width:"100%"}}>
          <div style={{fontFamily:"Pretendard-Bold", fontSize:16}}>홍여사 노출기준</div>
          <div onClick={_handleClose} style={{display:"flex"}}>
              <IoIosCloseCircle size={24}/>
            </div>
          </BetweenRow>  
          <ULITEM>
            <li>활동 뱃지를 10개 이상 획득한 홍여사가 보여집니다</li>
            <li>구해줘 홍여사에 파트너계약을 체결하고 보다 세밀한 인증심사를 거친 홍여사가 보여집니다</li>
          </ULITEM>
          </Column>
         
        </PopupGuide>
        }

    </Container>
  );

}

export default Hongcontainer;

