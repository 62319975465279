import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB, Seekimage } from "../../utility/imageData";

import { AroundRow, BetweenRow, FlexstartRow, Row } from "../../common/Row";
import { Column, FlexstartColumn } from "../../common/Column";
import { EventItems, EVENTTYPE, FILTERITMETYPE, INCLUDEDISTANCE, LoadingType, PCCOMMNUNITYMENU, PCMAINMENU } from "../../utility/screen";
import Communitymenu from "../../common/Communitymenu";
import CommunityItem from "../../components/CommunityItem";
import Empty from "../../components/Empty";
import Button from "../../common/Button";
import { DataContext } from "../../context/Data";
import { useSleep } from "../../utility/common";
import QRCode from 'qrcode.react';
import Loading from "../../components/Loading";
import { ReadWork } from "../../service/WorkService";
import PCRoomItem from "../../components/PCRoomItem";
import { ReadRoom } from "../../service/RoomService";
import StoreInfo from "../../components/StoreInfo";
import Position from "../../components/Position";
import { ROOMSIZE } from "../../utility/room";
import { useSelector } from "react-redux";
import MobileRoomItem from "../../components/MobileRoomItem";
import LottieAnimation from "../../common/LottieAnimation";
import { LoadingCommunityStyle } from "../../screen/css/common";
import { GoPlus } from "react-icons/go";
import PCBanner from "../../components/PCBanner";

const Container = styled.div`
  padding:0px 24px 100px 24px;

`
const style = {
  display: "flex"
};

const EventTitle = styled.div`
  font-size: 25px;
  line-height: 80px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  width : 85%;
  font-weight:500;

`
const EventBox = styled.div`

  margin-top:30px;
  width: calc(50% - 20px);
  margin-bottom: 30px;
  cursor: pointer;
  transition: .2s all;
  margin-left:15px;
  
`
const txtWrap = {
  backgroundColor:'#fafafa',
  padding: '18px 20px 24px',
  lineHeight:2
}

const tit ={
  fontSize: '18px',
  fontWeight: 700,
}

const day = {
  color: '#797979',
  fontSize: '16px'
}

const Label = styled.div`
  font-size: 20px;
  line-height: 26px;
  font-family:Pretendard-SemiBold;


`

const RoomItems=[
  {name :ROOMSIZE.ONESIZE, img:imageDB.roomsize1},
  {name :ROOMSIZE.TWOSIZE, img:imageDB.roomsize1},
  {name :ROOMSIZE.THREESIZE, img:imageDB.roomsize1},
  {name :ROOMSIZE.FOURSIZE, img:imageDB.roomsize1},
  {name :ROOMSIZE.FIVESIZE, img:imageDB.roomsize1},
  {name :ROOMSIZE.SIXSIZE, img:imageDB.roomsize1},
  {name :ROOMSIZE.SEVENSIZE, img:imageDB.roomsize1},
  {name :ROOMSIZE.EIGHTSIZE, img:imageDB.roomsize1},

]

const Box = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction:column;
  height: 90px;
  width: 12%;
  margin-right: 10px;
  margin-bottom: 40px;
  border-radius: 15px;

`
const BoxImg = styled.div`
  background: #f9f9f9;
  border-radius: 100px;
  background: ${({clickstatus}) => clickstatus == true ? ('#dcd8d8') :('#f9f9f9;') };
  padding: 10px;
`
const RegistButton = styled.div`
    height: 50px;
    width: 20%;
    border-radius: 100px;
    background: #FF7e19;
    color: #fff;
    margin: 30px auto 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    font-size: 24px;
`
const MainContent = styled.div`
  width: 50%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const MainContentTxt1 = styled.div`
  line-height: 1.5;
  font-size: 34px;
  letter-spacing: -0.32px;
  font-family :Pretendard-Bold;
`
const MainContentTxt2 = styled.div`

  line-height: 1.5;
  font-size: 22px;
  letter-spacing: -0.32px;
`

/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;


const PCRoomcontainer =({containerStyle}) =>  {

  const {value} = useSelector((state)=> state.menu);

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [roomitems, setRoomitems] = useState([]);
  const [currentloading, setCurrentloading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [menu, setMenu] = useState('');
  const [totalset, setTotalset] = useState(0);

  const _handlemenuclick =(menu) =>{

    setMenu(menu);

    setTotalset(6);


    setRefresh((refresh) => refresh +1);
  }


  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    setSearching(searching);
    setMenu(menu);
    setTotalset(totalset);
  },[refresh])

  const _handleroomselect = () =>{
    if(menu ==''){
      alert("대여할 공간형태를 선택해주세여");
      return;
    }
    navigate("/PCregist",{state :{WORKTYPE :menu, WORKTOTAL : totalset}});
  }



    

  const _handleSelectWork = (ROOM_ID) =>{
    navigate("/PCmap" ,{state :{ID :ROOM_ID, TYPE : FILTERITMETYPE.ROOM}});
  }


  const positioncallback = () =>{
    setCurrentloading(true);
    setRefresh((refresh) => refresh +1);
  } 
  
  const _handlecurrentloadingcallback = ()=> {
    setCurrentloading(false);
    setRefresh((refresh) => refresh +1);
  }
  return (
    <>
    {
      currentloading == true && <Loading type={LoadingType.CURRENTPOS} callback={_handlecurrentloadingcallback}/>
    }
    


    <PCBanner backgroundcolor={"#d2edfa"} main={'남아 있는 집 공간'} sub1={'짐 맡아주고 돈벌어보세요'} sub2={'구해줘 홍여사가 짐맡길분을 찾아줍니다'} image={imageDB.roomgroup} />
     
    <Container style={containerStyle}>
    <div style={{display:"flex", flexDirection:"column"}}>
      <Position type={PCMAINMENU.ROOMMENU} callback={positioncallback}/>
      {/* {
        searching == true  ? (<LottieAnimation containerStyle={LoadingCommunityStyle} animationData={imageDB.loadinglarge}
          width={"100px"} height={'100px'} />):(
            <>
              
              {
                  roomitems.length == 0 && <Empty content = "공간대여가 존재 하지 않습니다"
                  containerStyle={{marginTop:"150px"}}
                  />
              }
                
              <FlexstartRow style={{flexWrap:"wrap", justifyContent:"space-around"}}
              className="WorkLayer">
              {
                  roomitems.map((item, index)=>(
                    <MobileRoomItem key={index}  index={index} width={'23%'} roomdata={item} onPress={()=>{_handleSelectWork(item.ROOM_ID)}}/>
                  ))
                }
              </FlexstartRow>
            
            </>
          )
      } */}

        <FlexstartRow style={{background:"#fff", marginBottom:"20px", alignItems:"center", marginTop:"20px", height:"40px",justifyContent:"center"}}>
          {
               menu != '' ? (<>
                {/* <img src={Seekimage(menu)} style={{width:40, height:40, marginRight:20}}/> */}
                <Label>6단계만 거치면 대여할 공간 자유롭게 등록할수가 있습니다</Label>
                </>) :(<div>
                <Label>대여할 공간을 선택해 주세요</Label>
                </div>)
          }

            
            
        </FlexstartRow>


      <FlexstartColumn style={{width:'60%', margin:"10px auto 10px", justifyContent:"center"}}>
          {/* <TitleLayer><Title>대여할 공간을 선택해 주세요</Title></TitleLayer> */}
          <FlexstartRow style={{flexWrap:"wrap", width:"100%"}}>
            {
              RoomItems.map((data, index)=>(
                <Box onClick={()=>{_handlemenuclick(data.name)}} clickstatus={menu == data.name}>
      
                  <BoxImg clickstatus={menu == data.name}><img src={data.img} style={{width:64, height:64}}/></BoxImg>

                  <div style={{ fontSize:14, marginTop:10}}>{data.name}</div>
                </Box>
              ))
            }
          
          </FlexstartRow>


          <RegistButton onClick={_handleroomselect}><GoPlus size={34}/> 등록</RegistButton>

      </FlexstartColumn>

    </div>

  

    </Container>
    <StoreInfo />
    
    </>
  );

}

export default PCRoomcontainer;

