import React, { Component, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB, Seekimage } from "../../utility/imageData";
import PcAdvertisePopup from "../../modal/PcAdvertisePopup/PcAdvertisePopup";

import { BetweenRow, FlexstartRow, Row } from "../../common/Row";
import { Column, FlexstartColumn } from "../../common/Column";
import { CHATCONTENTTYPE, CHATIMAGETYPE, CONTACTTYPE, EventItems, PCCOMMNUNITYMENU } from "../../utility/screen";
import Communitymenu from "../../common/Communitymenu";
import CommunityItem from "../../components/CommunityItem";
import Empty from "../../components/Empty";
import Button from "../../common/Button";
import { ReadCommunity, ReadCommunitySummary, ReadCommunityTop10 } from "../../service/CommunityService";
import { DataContext } from "../../context/Data";
import { useSleep } from "../../utility/common";
import Chatgate from "../../components/Chatgate";
import { distanceFunc } from "../../utility/region";
import { CommaFormatted } from "../../utility/money";
import { WORKNAME,REQUESTINFO } from "../../utility/work";
import {
  SlShield,
  SlPaperClip,
  SlLogout,
  SlUserUnfollow,
} from "react-icons/sl";
import { CreateMessage } from "../../service/ChatService";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../api/config";
import { getDateFullTime, getTime, getDate } from "../../utility/date";
import LottieAnimation from "../../common/LottieAnimation";
import { LoadingChat2AnimationStyle, LoadingChatAnimationStyle } from "../../screen/css/common";
import { uploadImage } from "../../service/UploadService";
import MobileWarningPopup from "../../modal/MobileWarningPopup/MobileWarningPopup";
import { setRef } from "@mui/material";
import MobileContactSign from "../../modal/MobileContactSignPopup/MobileContactSignPopup";
import MobileContactDoc from "../../modal/MobileContactDocPopup/MobileContactDocPopup";
import MobilePayPopup from "../../modal/MobilePayPopup/MobilePayPopup";
import MobileWorkMapPopup from "../../modal/MobileMapPopup/MobileWorkMapPopup";
import MobileSignPopup from "../../modal/MobileSignPopup/MobileSignPopup";
import MobileSuccessPopup from "../../modal/MobileSuccessPopup/MobileSuccessPopup";
import MobileFailPopup from "../../modal/MobileFailPopup/MobileFailPopup";
import { CreateContact, ReadContactByIndividually, UpdateContactByLeftSign } from "../../service/ContactService";

const Container = styled.div`
    background-color : #f9f9f9;
    padding-top:50px;
`
const style = {
  display: "flex"
};

const HeaderPopupline = styled.div`

  width:20%;
  background:#E3E3E3;
  height:4px;
`
const IconCloseView = styled.div`

`

const ContactMain = styled.div`
  display: flex;
  justify-content: center;
  font-size: 25px;
  margin-bottom: 10px;
`
const ContactContent = styled.div`
  display: flex;
  justify-content: center;
  font-size: 15px;
  margin-bottom: 10px;
  line-height:1.8;
`

const MainData = styled.div`
  display :flex;
  flex-direction:column;
  background-color : #fff;
  flex-wrap : wrap;
  margin: 0 auto;
  width:100%;
  margin-left:-5px;
`
const ResultContent = {
  width: '100%',
  height: '150px',
  marginTop: '10px',
  marginBottom:'10px',
  fontSize: '14px',
  fontFamily: 'Pretendard-Light',
  lineHeight: 2,
  outline:"none",
  resize :"none",
  border :'1px solid #ededed',
  borderRadius:'5px',
  backgroundColor:"#fff",
  marginLeft:10

}

const CommentComponent = styled.textarea`
  width: 100%;
  height: 150px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: Pretendard-Light;
  line-height: 2;
  outline: none;
  resize: none;
  border :${({adjust}) => adjust == false ?(''):('1px solid #ddd')};
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
 
`

const InputComponent = styled.input`
  width: 95%;
  margin: 5px auto;
  border-top: none;
  border-right: none;
  border :${({adjust}) => adjust == false ? (''):('1px solid #ddd')};
  border-bottom: ${({adjust}) => adjust == false ? ('1px solid rgb(218, 218, 218)'):('')};
  border-left :${({adjust}) => adjust == false ? ('none'):('')};
  border-image: initial;
  border-radius: 0px;
  background-color: rgb(255, 255, 255);
  font-family: Pretendard-Light;
  flex: 0 0 auto;
`

const SignLine = styled.div`
  width: 100%;
  height: 150px;
  border: 1px solid #dfdfdf;
  display:flex;
  justify-content:center;
  align-items:center;
  font-size:10px;
  font-style: italic;
  background :#f4f4f4;
`
const SignItem = styled.div`
background: #4a4848;
color: #fff;
width: 102%;
display: flex;
justify-content: center;
height:50px;
align-items:center;
flex-direction:column;



`
const ShowContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom:100px;
  width:100%;

`;

const ItemLayerA = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5px;
  margin-bottom:5px;
`;
const ChatUserImg = styled.div`
  display: flex;
  justify-content: flex-start;
  color: #000;
  padding-left: 10px;
  font-size: 12px;
`;
const ItemLayerAname = styled.div`
  justify-content: flex-start;
  font-size: 12px;
  flex-direction: row;
  display: flex;
  padding-left: 10px;
`;
const ItemLayerAcontent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;
const ItemLayerAdate = styled.div`
  font-size: 10px;
  width: 100px;
  display: flex;
  justify-content: flex-start;
  padding-bottom:10px;
  color:#A3A3A3;
  flex-direction:column;
`;

const ItemLayerB = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right:20px;  
`;

const ItemLayerBBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;


`;

const ItemBoxA = styled.div`
  background: #F9F9F9;
  border-radius: 10px;
  padding: 10px;
  margin: 5px 10px 0px 5px;
  color: #131313;
  display: flex;
  flex-direction: column;
  width: 60%;
  font-size: 14px;
  text-align: left;
`;

const ItemBoxB = styled.div`
  background: #FFE477;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 10px 0px;
  color: #131313;
  display: flex;
  flex-direction: column;
  max-width: 50%;
  justify-content: flex-end;
  font-size: 14px;
  text-align: left;
`;


const ItemLayerBdate = styled.div`
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 2px;
  color:#A3A3A3;
  flex-direction:column;
  
`;
const ItemLayerBUnread = styled.div`
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 2px;
  color:#A3A3A3;
  flex-direction:column;

`



const PCContactWritecontainer =({containerStyle, WORKTYPE, messages,CHAT_ID,
ID,OWNER_ID,SUPPORTER_ID,NAME}) =>  {

  console.log("TCL: messages", messages)

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  const [popupstatus, setPopupstatus] = React.useState(false);
  const [signstatus, setSignstatus] = React.useState(false);
  const [signsuccess, setSignsuccess] = React.useState(false);
  const [signfail, setFailsuccess] = React.useState(false);

  const [latitude, setLatitude] = React.useState('');
  const [longitudie, setLongitude] = React.useState('');
  const [worktype, setWorktype] = React.useState(WORKTYPE);

  const [leftname, setLeftname] = React.useState('');
  const [leftsign, setLeftsign] = React.useState('');
  const [rightname, setRightname] = React.useState('');
  const [rightsign, setRightsign] = React.useState('');
  const [messageitems, setMessageitems] = React.useState(messages);
  const [left, setLeft] = React.useState(true);
  const [contactitem, setContactitem] = React.useState({});
  const [OWNER, setOWNER] = useState(false);

  const fileInput = useRef();

  const [chatitems, setChatitems] = useState([]);

  useEffect(()=>{

    setPopupstatus(popupstatus);  
    setSignstatus(signstatus);
    setSignsuccess(signsuccess);
    setLeftname(leftname);
    setLeftsign(leftsign);
    setRightname(rightname);
    setRightsign(rightsign);
    setOWNER(OWNER);


  }, [refresh])




  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);


  useEffect(() => {

    console.log("TCL: CHAT_ID", CHAT_ID)


    const q = query(
      collection(db, `CHAT/${CHAT_ID}/messages`),
      orderBy("CREATEDAT", "asc")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const list = [];
      querySnapshot.forEach(async (doc) => {
        list.push(doc.data());

   

      });


      setChatitems(list);
      console.log("TCL: unsubscribe -> list", list)

    

     
    });

    return () => unsubscribe();
  }, []);



  useEffect(()=>{
    async function FetchData(){

      // CONTACT에 저장된 글이 있는지 
      const CONTACTITEM = await ReadContactByIndividually({ID, OWNER_ID, SUPPORTER_ID});
      console.log("TCL: FetchData -> SUPPORTER_ID", SUPPORTER_ID);
      console.log("TCL: FetchData -> OWNER_ID", OWNER_ID);
      console.log("TCL: FetchData -> user", user);

      console.log("TCL: FetchData -> CONTACTITEM", CONTACTITEM);

      if(user.users_id == OWNER_ID){
        setOWNER(true);
        setLeft(false);
        setLeftname(NAME);
        setRightname(user.nickname);
      }else{
        setOWNER(false);
        setLeft(true);
        setLeftname(user.nickname);
      } setLeftname(NAME);

      if(CONTACTITEM != -1){
        setMessageitems(CONTACTITEM.CONTACT_INFO);
        setContactitem(CONTACTITEM);
      }

  
      // SIGN 여부 
      if(CONTACTITEM.RIGHT_SIGN != undefined){
        setRightsign(CONTACTITEM.RIGHT_SIGN);
  
      }

      if(CONTACTITEM.LEFT_SIGN != undefined){
        setLeftsign(CONTACTITEM.LEFT_SIGN);
    
      }

      console.log("TCL: FetchData -> RIGHT_SIGN", CONTACTITEM.RIGHT_SIGN)


      setRefresh((refresh) => refresh+1);

    }

    FetchData();
  },[])



  const popupcallback = async () => {
    setPopupstatus(false);
    setRefresh((refresh) => refresh +1);
  };

  const signcallback = async (data) => {
  
    console.log("TCL: signcallback -> data : ", data)

    if(data ==''){
      setSignstatus(false);
      setRefresh((refresh) => refresh +1);
      return;
    }

    setSignstatus(false);
    setSignsuccess(true);

    if(OWNER == false){
      setLeftsign(data);
      const CONTACT_STATUS = CONTACTTYPE.SUPPROTER;

      const LEFT_SIGN = data;

      const CONTACT_ID = contactitem.CONTACT_ID;
      console.log("TCL: signcallback -> contactitem", contactitem)

      await UpdateContactByLeftSign({CONTACT_ID, LEFT_SIGN});

    }else{
      setRightsign(data);
      const CONTACT_STATUS =CONTACTTYPE.OWNER;
      const CONTACT_INFO = messageitems;

      const RIGHT_SIGN = data;
  
      const createcontact =
      await CreateContact({OWNER_ID, CONTACT_STATUS, SUPPORTER_ID,CONTACT_INFO,ID, RIGHT_SIGN});
    }

    setRefresh((refresh) => refresh +1);
  };
  const signsuccesscallback = async () =>{
    setSignsuccess(false);
    setRefresh((refresh) => refresh +1);
  }
  const signfailcallback = async () =>{
    setFailsuccess(false);
    setRefresh((refresh) => refresh +1);
  }

  const _handleMapview= (lat, long, worktype)=>{

    setPopupstatus(true);
    setLatitude(lat);
    setLongitude(long);
    setWorktype(worktype);

    setRefresh((refresh) => refresh +1);

  }

  const _handleleftSign = async() =>{

    if(OWNER){
      return;
    }

    if(rightsign ==''){
      setFailsuccess(true);
      return;
    }
    setSignstatus(true);
    setLeft(true);
    setRefresh((refresh) => refresh +1);
  }
  const _handlerightSign = async() =>{

    if(!OWNER){
      return;
    }

    setSignstatus(true);
    setLeft(false);
    setRefresh((refresh) => refresh +1);
  }


  return (
    <Container style={containerStyle}>

        {
          popupstatus == true && <MobileWorkMapPopup callback={popupcallback} latitude={latitude} longitude={longitudie}
          top={'30%'}  left={'10%'} height={'280px'} width={'280px'} name={worktype} markerimg={Seekimage(worktype)}
          />
        }

        {
          signstatus == true && <MobileSignPopup callback={signcallback} 
          top={'30%'}  left={'10%'} height={'130px'} width={'280px'} 
          />
        }

        {
          signsuccess == true && <MobileSuccessPopup callback={signsuccesscallback} content={'정상적으로 서명되었습니다'} />
        }

        {
          signfail == true && <MobileFailPopup callback={signfailcallback} content={'의뢰자가 먼저서명 한후에 서명하실수 있습니다. 의뢰자에게 서명 하도록 요청해주세요'} />
        }

        {/* <BetweenRow style={{width:"80%", margin: "20px auto", }}>
            <div style={{fontSize:"18px", fontWeight:900, color:"#131313", fontFamily:'Pretendard-SemiBold'}}>홍여사 계약서 작성</div>
            <IconCloseView onClick={handleClose} >
            <img src={imageDB.close} style={{width:"22px", height:"22px"}}/>
            </IconCloseView>
        </BetweenRow> */}

        <div style={{width:"30%", margin:"0px auto", padding:20, background:"#fff"}}>
        <ContactMain>용역 계약서</ContactMain> 
        <ContactContent>
          본 계약서(이하 "계약서")는 [계약 체결일]에 [용역 수요자] (이하 "수요자")와 [용역 제공자] (이하 "제공자") 간에 체결되었습니다.
        </ContactContent>
        <ContactContent>
          1. 계약 목적
          본 계약은 제공자가 수요자에게 다음의 용역을 제공함에 있어 필요한 사항을 규정함을 목적으로 합니다.
            - [용역 내용 아래의 표에 기재]
        </ContactContent>
        <ContactContent>
          2. 용역 수행 및 기간
          홍여사는 [용역 요청일]에 본 계약에 명시된 용역을 성실히 수행해야 합니다.
        </ContactContent>
        <ContactContent>
          3. 비밀 유지
          제공자는 용역 수행 중 의뢰자로부터 제공받은 모든 개인 정보를 계약 종료 후에도 비밀로 유지해야 합니다.
        </ContactContent>

        <ContactContent>
        4. 분쟁 해결
          계약과 관련된 모든 분쟁은 [관할 법원]에서 해결합니다.
          본 계약의 효력을 확정하기 위해, 당사자 양측은 아래에 서명합니다.
        </ContactContent>


        <MainData>
    
       
            {
              messageitems.map((data, index)=>(
                <>
                {
                (data.type =='response' && data.requesttype !='고객님성별'
                && data.requesttype !='요청성별'
                && data.requesttype !='요청연령대')
                  &&
                <FlexstartColumn style={{marginTop:10}}>
                
                <div  style={{width:"70px", fontSize:14, paddingLeft:5}}>{data.requesttype}</div>
              
                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",width:"100%"}}>
                {
                  (data.requesttype == REQUESTINFO.COMMENT)  ? (
                  <CommentComponent value={data.result}
                    adjust ={OWNER == true && leftsign == ''}
                    disabled ={OWNER == false || leftsign != '' }
                    onChange={(e) => {
                      messageitems[index].result =e.target.value;; 
                      setMessageitems(messageitems);
                      setRefresh((refresh) => refresh +1);
                  }}
                  />):(
                    <>
                    {
                      
                    (data.requesttype != REQUESTINFO.CUSTOMERREGION 
                      && data.requesttype != REQUESTINFO.POSITION
                      && data.requesttype != REQUESTINFO.REQDATE
                      && data.requesttype != REQUESTINFO.MONEY)  &&  
                    <InputComponent  
                      adjust ={OWNER == true && leftsign == ''}
                      type={'text'} value={data.result}
                      class="input"
                      disabled ={OWNER == false || leftsign != '' }
                      onChange={(e) => {
                        messageitems[index].result =e.target.value;; 
                        setMessageitems(messageitems);
                        setRefresh((refresh) => refresh +1);
                    }}/>
                
                    }
                    </> 
                )
                }  

                {
                  data.requesttype == REQUESTINFO.MONEY &&
                  <InputComponent  
                  adjust ={OWNER == true && leftsign == ''}
                  type={'number'} value={data.result}
                  class="input"
                  disabled ={OWNER == false || leftsign != '' }
                  onChange={(e) => {
                    messageitems[index].result =e.target.value;; 
                    setMessageitems(messageitems);
                    setRefresh((refresh) => refresh +1);
                  }}/>

                }

              {
                  data.requesttype == REQUESTINFO.REQDATE &&
                  <InputComponent  
                  adjust ={OWNER == true && leftsign == ''}
                  type={'text'} value={data.result}
                  class="input"
                  disabled ={OWNER == false || leftsign != '' }
                  onChange={(e) => {
                    messageitems[index].result =e.target.value;; 
                    setMessageitems(messageitems);
                    setRefresh((refresh) => refresh +1);
                  }}/>

                }


                {
                  data.requesttype == REQUESTINFO.CUSTOMERREGION &&
                  <Row style={{justifyContent:"flex-start", paddingLeft:5, display:"flex", marginBottom:10}}>
                  <div style={{justifyContent:"flex-start", paddingTop:10,color:"#666"}}>{data.result}</div>
                  <div style={{display:"flex", paddingLeft:10}} onClick={()=>{_handleMapview(data.latitude,data.longitude, messages.WORKTYPE)}}><img src={imageDB.map} style={{width:20}}/> </div>
                  </Row>

                }

                {
                  data.requesttype == REQUESTINFO.POSITION &&
                  <Row style={{justifyContent:"flex-start", paddingLeft:5, display:"flex", marginBottom:10}}>
                  <img src= {data.result}/>
    
                  </Row>

                }
                </div>
              
              
                </FlexstartColumn>
                
                }
                </>                  
              ))
            }
      

          <Row>
            계약 체결일 : {getDateFullTime(moment())}
          </Row>

          <BetweenRow style={{width:"100%", margin:"20px auto"}}>
            <Column style={{width:"100%", margin:"20px auto", marginRight:'5px'}}>
            <SignItem>
              <div>홍여사 서명</div>
              <div>{leftname}</div>
            </SignItem>
            <SignLine>
              {
                leftsign == '' ?( <div onClick={_handleleftSign}>
                  {
                    OWNER == false && <span style={{fontSize:16}}>이곳에 서명해주세요</span>
                  }
                
                  
                  </div>):(
                  <div onClick={_handleleftSign}>
                    <img src={leftsign} style={{width:150, height:150}}/>
                  </div>
                )
              }
              
            </SignLine>
            </Column>
            <Column style={{width:"100%", margin:"20px auto"}}>
            <SignItem>
              <div>의뢰자 서명</div>
              <div>{rightname}</div>
            </SignItem>
            <SignLine>
            {
                rightsign == '' ? (<div onClick={_handlerightSign}>

                {
                    OWNER == true && <span style={{fontSize:16}}>이곳에 서명해주세요</span>
                }

                </div>):(
                  <div onClick={_handlerightSign}>
                    <img src={rightsign} style={{width:150, height:150}}/>
                  </div>
                )
              }
            </SignLine>
            </Column>
          </BetweenRow>
      


        </MainData>
        </div>

        <div style={{width:"30%", margin:"20px auto", padding:20, background:"#fff"}}>
             <ContactMain>용역 계약서 첨부자료</ContactMain> 
             <ShowContainer>
              {chatitems.map((data, index) => (
                <>
            
                  {(data.CHAT_CONTENT_TYPE != CHATCONTENTTYPE.EXIT
                  && data.CHAT_CONTENT_TYPE != CHATCONTENTTYPE.ENTER) &&
                    <>
                      {user.users_id != data.USERS_ID ? (
                        <ItemLayerA>
                          <Row>
             
                            <FlexstartColumn>
                              <ItemLayerAname>
                                  {leftname}
                              </ItemLayerAname>

                              <ItemLayerAcontent>
                                {
                                  data.CHAT_CONTENT_TYPE == CHATCONTENTTYPE.IMAGE ? (<img src={data.TEXT}
                                    style={{width: '70%',
                                    height: '250px',
                                    padding: '10px',
                                    borderRadius: '20px'  
                                    }}
                                  />):( <ItemBoxA>{data.TEXT}</ItemBoxA>)
                                }
                              
                                <ItemLayerAdate>
                                  <div> {getDate(data.CREATEDAT)}</div>
                                  <div> {getTime(data.CREATEDAT)}</div>
                                </ItemLayerAdate>
                              </ItemLayerAcontent>
                            </FlexstartColumn>
                          </Row>
                        </ItemLayerA>
                      ) : (
                        <ItemLayerB>
                          <ItemLayerBBox style={{width:"10%",justifyContent:"space-between", flexDirection:"row"}}>
                            {
                              data.READ.length == 1 && <ItemLayerBUnread>1</ItemLayerBUnread>
                            }
                          
                            <ItemLayerBdate>
                              <div> {getDate(data.CREATEDAT)}</div>
                              <div> {getTime(data.CREATEDAT)}</div>
                             
                              </ItemLayerBdate>
                          </ItemLayerBBox>
                          {
                            data.CHAT_CONTENT_TYPE == CHATCONTENTTYPE.IMAGE  ? (<img src={data.TEXT}
                              style={{
                              height: '250px',
                              padding: '10px',
                              borderRadius: '20px'  
                              }}
                            />):( <ItemBoxB>{data.TEXT}
                        
                            </ItemBoxB>)
                          }
                     
                        </ItemLayerB>
                      )
                      }
                    
                    </>
                  }
            
                  
                </>
              ))}

         
            </ShowContainer>
        </div>

    </Container>
  );

}

export default PCContactWritecontainer;

