import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB, Seekimage } from "../../utility/imageData";
import PcAdvertisePopup from "../../modal/PcAdvertisePopup/PcAdvertisePopup";

import StoreInfo from "../../components/StoreInfo";
import { DataContext } from "../../context/Data";


import { ReadWork } from "../../service/WorkService";
import { BetweenRow, FlexstartRow, Row } from "../../common/Row";
import Loading from "../../components/Loading";
import { FILTERITMETYPE, INCLUDEDISTANCE, LoadingType, PCMAINMENU } from "../../utility/screen";
import Position from "../../components/Position";
import { WORKNAME, WORKPOLICY } from "../../utility/work";
import { useSelector } from "react-redux";
import { Column, FlexstartColumn } from "../../common/Column";
import MobileWorkItem from "../../components/MobileWorkItem";
import Empty from "../../components/Empty";
import localforage from 'localforage';
import { getDateEx, getDateFullTime } from "../../utility/date";
import { readuserbydeviceid, Readuserbyusersid } from "../../service/UserService";
import { sleep } from "../../utility/common";
import LottieAnimation from "../../common/LottieAnimation";
import { LoadingCommunityStyle } from "../../screen/css/common";
import { GoPlus } from "react-icons/go";
import IconButton from "../../common/IconButton";
import MobileRoomItem from "../../components/MobileRoomItem";
import { ReadRoom } from "../../service/RoomService";
import { IoIosCloseCircle } from "react-icons/io";
import PCBanner from "../../components/PCBanner";

const Container = styled.div`
  padding:0px 24px 0px 24px;

`


const style = {
  display: "flex"
};

const Label = styled.div`
  font-size: 23px;
  line-height: 26px;
  font-family:Pretendard-SemiBold;


`
const RegistButton = styled.div`
    height: 50px;
    width: 131px;
    border-radius: 100px;
    background: #FF7125;
    color: #fff;
    margin: 0px auto 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    font-size: 24px;
`
const Box = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction:column;
  height: 90px;
  width: 12%;
  margin-right: 10px;
  margin-bottom: 40px;
  border-radius: 15px;

`
const BoxImg = styled.div`
  background: #f9f9f9;
  border-radius: 100px;
  background: ${({clickstatus}) => clickstatus == true ? ('#ff7e19a8') :('#f9f9f9;') };
  border: ${({clickstatus}) => clickstatus == true ? ('3px solid #FF7125') :('') };
  padding: 10px;
`

const FilterEx2 = styled.div`
  position: fixed;
  width: 200px;
  height: 100%;
  z-index: 2;
  padding: 0px 10px;
  bottom: 0px;
  overflow-y: auto;
  right: 0px;
  display: flex;
  flex-direction: row;

`
const ButtonSt = styled.div`

  border: 1px solid #ededed;
  padding: 10px;
  background: ${({enable}) => enable == true ?('#ff7e19'):('#f6f6f6')};
  color: ${({enable}) => enable == true ?('#fff'):('#131313')};
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`

const ULITEM = styled.ul`
  padding-left: 15px;
  list-style-type: disc;
  line-height: 2;
  margin-top: 10px;
  font-family:Pretendard-Light;
`
const PopupGuide = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #FFF;
  border : 1px solid #ededed;
  padding: 20px;
  color: #131313;
  border-radius: 10px;

`
const MainContent = styled.div`
  width: 50%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const MainContentTxt1 = styled.div`
  line-height: 1.5;
  font-size: 33px;
  letter-spacing: -0.32px;
  font-family :Pretendard-Bold;
`
const MainContentTxt2 = styled.div`

  line-height: 1.5;
  font-size: 22px;
  letter-spacing: -0.32px;
`


/**
 * 메인 데이타페이지는 
 * ! currentloading이 false 상태 일때만 보여준다
 * TODO 로딩 타입
 * ① 지역설정 타입 currentloading
 */

const PCHongcontainer =({containerStyle}) =>  {

  const {value} = useSelector((state)=> state.menu);

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [popupstatus, setPopupstatus] = useState(false);

  const [workitems, setWorkitems] = useState([]);
  const [roomitems, setRoomitems] = useState([]);
  const [currentloading, setCurrentloading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [menu, setMenu] = useState(PCMAINMENU.HOMEMENU);

  const [items, setItems] = useState([]);

  const [guideclose, setGuideclose] = useState(true);

  
  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    setCurrentloading(currentloading);
    setSearching(searching);
    setMenu(menu);
    setGuideclose(guideclose);

  },[refresh])


  const positioncallback = () =>{

  }
  /**
   * useSelector menu 가 변경됨을 감지 함에 따라 호출되는  Hook 함수
   * 데이타를 서버로 부터 불러와서 FilterwokrItems 함수로 걸러진값을 workitems 에 설정 해준다
   */

  useEffect(()=>{

    setSearching(true);
    console.log("useEffect value=====>", value);
    async function FetchData(){

      setSearching(false);
    }
    localforage.getItem('userconfig')
    .then(function(value) {
      console.log("TCL: listener -> App userconfig", value);
      user.latitude =value.latitude;
      user.longitude =value.longitude;
      user.radius =value.radius;
      user.deviceid = value.deviceid;
      user.address_name = value.address_name;

      user.token = value.token;
      user.phone = value.phone;
      user.userimg = value.userimg;
      user.users_id = value.users_id;
      user.radius = value.radius;



      dispatch(user);

      FetchData();
    })
    .catch(function(err) {

    });

    setRefresh((refresh) => refresh +1);
  },[value])


  const _handleClose =() =>{
    setGuideclose(false);
  }




  return (

    <>


    <PCBanner main={'직접 홍여사에 연락해보세요'} backgroundcolor={"#ffe2d2"} sub1={'우리 동네에서 가장 일 잘하는 홍여사'} 
    sub2={'엄격한 조건에 의해 선별된 홍여사'} image={imageDB.worker}  imagewidth={'180px'} />

    <Container style={containerStyle}>

    <div style={{display:"flex", flexDirection:"column"}}>


        
        {
          guideclose == true && <PopupGuide>
          <Column>
          <BetweenRow style={{width:"100%"}}>
          <div style={{fontFamily:"Pretendard-Bold", fontSize:18}}>홍여사 노출기준</div>
          <div onClick={_handleClose} style={{display:"flex", marginLeft:50}}>
              <IoIosCloseCircle size={24}/>
          </div>
          </BetweenRow>  
          <ULITEM>
            <li>활동 뱃지를 10개 이상 획득한 홍여사가 보여집니다</li>
            <li>구해줘 홍여사에 파트너계약을 체결하고 보다 세밀한 인증심사를 거친 홍여사가 보여집니다</li>
          </ULITEM>
          </Column>
         
        </PopupGuide>
        }

    </div>
    </Container>
    </>



  );

}

export default PCHongcontainer;

